import { getImbyDomain } from './getImbyDomain'

const initMixPanel = (mixpanel) => {
  const isProduction = location.hostname === 'canada.addyinvest.com'
  const env = isProduction ? 'production' : 'devOrTest'
  const projectToken = new Map()
  projectToken.set('production', '9c176770ecfad527aaaf79dc6baf988f')
  projectToken.set('devOrTest', '050fb576f2aa884646eecd31917d246e')
  const isDevelopment = location.hostname === 'localhost' || location.hostname === '127.0.0.1'
  const proxyHost = isDevelopment ? `http://${location.hostname}:3000` : getImbyDomain()
  mixpanel.init(projectToken.get(env), { api_host: proxyHost })
}

export {
  initMixPanel
}
