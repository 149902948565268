<template>
  <aside id="desktop-side-menu">
    <a @click="toLink('/d/dashboard')" class="logo">
      <img src="@assets/images/common/logo.png" alt="logo">
    </a>
    <section>
      <div
        v-for="(link, index) in links"
        :key="index"
        class="link-block"
        @click="toLink(link.path)"
      >
        <div :class="`is-flex is-relative is-align-items-center link-wrapper ${$route.path === link.path ? 'is-active': ''}`">
          <img :src="`https://addy-public.s3.us-west-2.amazonaws.com/desktop-menu-${link.icon}.svg`" :alt="link.text">
          <span class="has-text-blue text">{{link.text}}</span>
        </div>
      </div>
    </section>
  </aside>
</template>
<script>
const LINKS = [
  {
    text: 'Dashboard',
    icon: 'dashboard',
    path: '/d/dashboard',
  },
  {
    text: 'Portfolio',
    icon: 'portfolio',
    path: '/portfolio',
  },
  {
    text: 'Properties',
    icon: 'properties',
    path: '/allProperties',
  },
  {
    text: 'Wallet',
    icon: 'wallet',
    path: '/wallet',
  },
  {
    text: 'Profile',
    icon: 'profile',
    path: '/investorProfile',
  },
]
export default {
  data() {
    return {
      active: undefined,
      links: Object.freeze(LINKS),
    }
  },
  methods: {
    toLink(path) {
      if (this.$route.path === path) return
      this.$router.push(path)
    }
  },
}
</script>

<style lang="scss" scoped>
#desktop-side-menu {
  width: 226PX;
  .logo {
    display: block;
    width: 41PX;
    margin: 20PX auto 68PX;
    img {
      width: 100% !important;
    }
  }
  .link-block {
    height: 40PX;
    margin-bottom: 16PX;
    padding-left: 40PX;
    cursor: pointer;
    .link-wrapper {
      height: 100%;
      filter: grayscale(1);
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: white;
        opacity: 0.7;
      }
      &:hover,
      &.is-active {
        filter: grayscale(0);
        &:before {
          opacity: 0;
        }
      }
      img {
        width: 22PX;
      }
      .text {
        font-size: 16PX;
        padding-left: 12PX;
      }
    }
  }
}
</style>
