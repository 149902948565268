export const getImbyDomain = () => {
  switch (window.location.hostname) {
  case 'canada.addyinvest-integration.com':
  case 'app.addyinvest-integration.com':
    return 'https://internal.addyinvest-integration.com'
  case 'canada.addyinvest-staging.com':
  case 'app.addyinvest-staging.com':
    return 'https://internal.addyinvest-staging.com'
  case 'canada.addyinvest.com':
  case 'app.addyinvest.com':
    return 'https://internal.addyinvest.com'
  default:
    return 'http://localhost:3000'
  }
}
