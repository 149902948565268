import { embeddedInIos } from '@utils/common-methods/common'
import { getMembershipDetails } from '@api/common'
import store from '../../store'
import jwtDecode from 'jwt-decode'

const isOnboardingCompleted = (investor) => {
  const {
    firstName,
    lastName,
    mobileNumber,
    birthdate,
    streetNumber,
    mailingStreetNumber,
    suitabilityAnswers,
    country,
  } = investor
  const signUpCompleted = firstName && lastName && mobileNumber && birthdate && streetNumber && mailingStreetNumber
  return (country && country !== 'United States') ? signUpCompleted : (signUpCompleted && suitabilityAnswers)
}

const restrictedByHoldingPen = ({ notRestrictedByHoldingPen }) => {
  if (!process.env.VUE_APP_US_HOLDING_PEN) return !notRestrictedByHoldingPen
  return JSON.parse(process.env.VUE_APP_US_HOLDING_PEN) && !notRestrictedByHoldingPen
}

const storeMembershipDetails = () => {
  const membershipDetails = localStorage.getItem('membershipDetails')
  if (membershipDetails) return
  getMembershipDetails().then((response) => {
    if (response.success) localStorage.setItem('membershipDetails', JSON.stringify(response.data))
  })
}

const navigateWithRouteGuard = ({ to, from, next }) => {
  const toAddyPlus = to.path.startsWith('/addyPlus')

  // Validate addyPlus resource to check if access token is mfa authenticated
  if (JSON.parse(process.env.VUE_APP_MFA_ENABLED || false) && toAddyPlus && localStorage.getItem('access_token')) {
    const decoded = jwtDecode(localStorage.getItem('access_token'))
    const mfaAuthenticatedAt = decoded['https://addy.com/mfa_authenticated_at']
    // Check if access token has mfa_authenticated_at (required in addyPlus resource) else redirect to addyPlus login.
    if (mfaAuthenticatedAt) {
      // Check if MFA Required after days is declared for checking if mfa_authenticated_at is still valid.
      // If not declared, there is no restriction for addyPlus as long as mfa_authenticated_at is present.
      if (process.env.VUE_APP_MFA_REQUIRED_AFTER_DAYS > 0) {
        const daysAgo = new Date()
        const mfaAuthenticatedDate = new Date(mfaAuthenticatedAt)
        daysAgo.setDate(daysAgo.getDate() - process.env.VUE_APP_MFA_REQUIRED_AFTER_DAYS)
        // Check if mfa_authenticated_date is less than days ago based on the required after-days value, then redirect to addyPlus login.
        if (mfaAuthenticatedDate < daysAgo) return next({ path: '/addyPlus/login' })
      }
    } else return next({ path: '/addyPlus/login' })
  }

  if (toAddyPlus) return next()

  // force user to complete account verification and the onboarding flow
  const applyUsHoldingPenGuard = restrictedByHoldingPen(to.meta)
  if (to.meta.profileSetupExemption && !applyUsHoldingPenGuard) return next()

  const showLoginDisclaimer = !embeddedInIos() && !to.meta.profileSetupExemption && !localStorage.getItem('disclaimer_shown')

  const investor = localStorage.getItem('investor')
  if (!investor) return next({ path: '/login' })

  const {
    email,
    emailVerifiedAt,
    country,
    eligibleUsAccreditedAccess,
  } = JSON.parse(investor)
  const onboardingCompleted = isOnboardingCompleted(JSON.parse(investor))
  if (!emailVerifiedAt) next({ path: `/signup/verify?email=${encodeURIComponent(email)}` })
  else if (!onboardingCompleted) next({ path: '/signup/profile/1' })
  else if (country.toLowerCase() !== 'canada' && applyUsHoldingPenGuard && !eligibleUsAccreditedAccess) { // currently restrict American retail users to the holding pen
    if (embeddedInIos()) window.webkit.messageHandlers.closeWebview.postMessage(true)
    else next({ path: '/comingSoon' })
  } else {
    if (country.toLowerCase() === 'canada' && showLoginDisclaimer) {
      store.commit('toggleLoginDisclaimer', true)
    }
    storeMembershipDetails()
    next()
  }
}

export {
  isOnboardingCompleted,
  navigateWithRouteGuard,
}
