<template>
  <div id="spa">
    <DesktopMenu v-if="showDesktopCommonComponents"></DesktopMenu>
    <main id="desktop-main-wrap" :class="`${$isMobile ? '' : 'is-relative'}`">
      <DesktopHeader v-if="showDesktopCommonComponents"></DesktopHeader>
      <div id="desktop-scroll-container">
        <div id="app-wrapper">
          <div id="desktop-teleport"></div>
          <div :class="`${embeddedInIos ? 'is-embedded-in-ios' : ''}`" id="app">
            <router-view></router-view>
            <Menu></Menu>
            <b-loading :is-full-page="true" v-model="isLoading">
              <img src="./assets/images/common/loading.png" alt="loading" class="loading-icon">
            </b-loading>
          </div>
          <DesktopFooter v-if="showDesktopCommonComponents"></DesktopFooter>
        </div>
      </div>
    </main>
    <DisclaimerModal
      @closeDisclaimerModal="closeDisclaimerModal"
      type="atlasOneLogin"
      v-if="showLoginDisclaimer"
    ></DisclaimerModal>
  </div>
</template>
<script>
import Menu from './views/index/Menu.vue'
import DesktopMenu from './views/index/DesktopMenu.vue'
import DesktopHeader from './views/index/DesktopHeader.vue'
import DesktopFooter from './views/index/DesktopFooter.vue'
import DisclaimerModal from '@components/modal/disclaimer-modal.vue'
import setViewportHeight from '@utils/mixins/set-viewport-height.js'
import mixpanel from 'mixpanel-browser'
import { getFeatureToggle, getFeatureToggleList } from '@api/common'
import { initMixPanel } from '@utils/common-methods/initMixPanel'
import { initializeIntercom } from '@utils/common-methods/intercom'
import { getInvestorData } from '@api/signup'
import { mapState } from 'vuex'
import VConsole from 'vconsole'
import { embeddedInIos } from '@utils/common-methods/common'

export default {
  name: 'spa',
  mixins: [setViewportHeight],
  mounted() {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    this.$store.commit('mixpanel/setMixpanel', mixpanel)
    initMixPanel(mixpanel)

    this.initInvestorData()
    this.initVconsole()
    this.fetchAllToggles()
    this.integrateNps()
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapState(['showLoadingMask', 'showDesktopCommon', 'showLoginDisclaimer']),
    ...mapState('mixpanel', ['mixpanel', 'identityIsSet']),
    showDesktopCommonComponents() {
      return !this.$isMobile && this.showDesktopCommon
    },
    embeddedInIos() {
      return embeddedInIos()
    },
  },
  watch: {
    showLoadingMask(newValue) {
      this.isLoading = newValue
    },
  },
  components: {
    Menu,
    DesktopMenu,
    DesktopHeader,
    DesktopFooter,
    DisclaimerModal,
  },
  methods: {
    initVconsole() {
      getFeatureToggle('mobile_virtual_console').then((data) => {
        if (!data.success) return
        /* eslint-disable no-new */
        if (data.data.enabled) new VConsole()
      })
    },
    fetchAllToggles() {
      getFeatureToggleList().then((result) => {
        if (!result.success) return
        this.$store.commit('setFlipperToggles', result.data)
        this.addIntercom(result.data.mobileIntercom)
      })
    },
    addIntercom(status) {
      if (status) initializeIntercom()
    },
    initInvestorData() {
      localStorage.getItem('investor') && getInvestorData().then((data) => {
        localStorage.setItem('investor', JSON.stringify(data))
        if (!this.identityIsSet && data) {
          this.mixpanel.identify(data.email)
          this.$store.commit('mixpanel/setIdentity', true)
        }
      })
    },
    integrateNps() {
      // Delighted NPS snippet
      /* eslint-disable-next-line */
      !function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],s=0;s<i.length;s++){var c=i[s];a[c]=a[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(c)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var l=t.getElementsByTagName("script")[0];l.parentNode.insertBefore(o,l)}}(window,document,"nV8zlxFQbnQdhCCU","delighted");
    },
    closeDisclaimerModal() {
      this.$store.commit('toggleLoginDisclaimer', false)
      localStorage.setItem('disclaimer_shown', true)
    },
  },
}
</script>
<style lang="scss" scoped>
.loading-icon {
  width: 58px;
  animation: fa-spin 2s infinite linear;
}
</style>
