/**
 * Convert the array data of corporations to an object structure for the sidemenu to aid performance
 * @param {Array} arr list of corporations
 * @return {Oject}
 */
const corporationsArray2Object = (arr) => {
  const obj = {}
  arr.map((corporation) => {obj[corporation.id] = corporation})
  return obj
}

export default {
  namespaced: true,
  state: () => {
    return {
      showSideMenu: false,
      accounts: [],
      logoUrl: 'https://addy-public.s3.us-west-2.amazonaws.com/logo_prussian_blue.svg',
      activeAccount: '',
      corporations: undefined, // Array | undefined
      corporationsForSideMenu: {},
      permissions: {},
    }
  },
  getters: {
    parsedAccounts(state) {
      const temp = {}
      state.accounts.map((account) => {
        temp[account.id] = account
      })
      return temp
    }
  },
  mutations: {
    setAccounts(state, payload) {
      state.accounts = payload.map((account) => {return { ...account, logo: account.logo || state.logoUrl }})
    },
    setLogo(state, payload) {
      state.logoUrl = payload
    },
    setActiveAccount(state, payload) {
      state.activeAccount = payload
    },
    setCorporations(state, payload) {
      state.corporations = payload
    },
    setCorporationsForSideMenu(state, payload) {
      state.corporationsForSideMenu = payload
    },
    setSideMenuToggleStatus(state, payload) {
      sessionStorage.setItem('showAddyPlusSideMenu', payload)
      state.showSideMenu = payload
    },
    setPermissions(state, payload) {
      state.permissions = payload
    },
  },
  actions: {
    setCorporations({ commit }, payload) {
      commit('setCorporations', payload)

      const parsedData = corporationsArray2Object(payload)
      commit('setCorporationsForSideMenu', parsedData)
    }
  }
}
