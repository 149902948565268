import axios from '@lib/axios/middleware'
import { getImbyDomain } from '@utils/common-methods/getImbyDomain'

const getImbyRoute = (url) => {
  if (window.location.hostname === 'localhost') return url
  return getImbyDomain() + url
}

/**
 * update investor flags
 * @param {Object} payload referral_intro_modal_seen_at, referral_gift_icon_seen_at, interac_deprecation_modal_seen_at
 * @return {Promise}
 */
export const updateInvestorFlags = (payload) => {
  return axios.put(getImbyRoute('/api/v1/investor_flags'), { investor_flag: payload })
}

/**
 * get investor flags
 * @return {Promise}
 */
export const getInvestorFlags = () => {
  return axios.get(getImbyRoute('/api/v1/investor_flags/details'))
}

/**
 * get Flipper feature toggle
 * TODO: deprecate the usage of this endpoint and use the toggle value from getFeatureToggleList
 * @param {String} payload feature
 * @return {Promise}
 */
export const getFeatureToggle = (payload) => {
  return axios.get(getImbyRoute(`/api/v1/flipper/enabled?feature=${payload}`))
}

/**
 * fetch for all the Flipper feature toggles
 * @return {Promise}
 */
export const getFeatureToggleList = () => {
  return axios.get(getImbyRoute('/api/v1/flipper/features_list'))
}

/**
 * Fetching Document File URL
 * @param {Object} payload model_name, hashid, document_type
 * @return {Promise}
 */
export const fetchDocUrl = (payload) => {
  return axios.get(getImbyRoute('/api/v1/documents/file_url'), { params: payload })
}

/**
 * Get Membership Status
 * @return {Promise}
 */
export const getMembershipStatus = () => {
  return axios.get(getImbyRoute('/api/v1/investors/membership_paid'))
}

/**
 * Get instant funds, purchase limit amounts for all 3 memberships
 * @return {Promise}
 */
export const getMembershipDetails = () => {
  return axios.get(getImbyRoute('/api/v1/membership_charges/membership_details'))
}

/**
 * Fetch for banner notification
 * @return {Promise}
 */
export const getBannerNotification = () => {
  return axios.get(getImbyRoute('/api/v1/banner_notifications'))
}

/**
 * Toggle off banner notification
 * @param {Object} bannerNotificationId
 * @return {Promise}
 */
export const toggleOffBannerNotification = (bannerNotificationId) => {
  return axios.put(getImbyRoute(`/api/v1/banner_notifications/${bannerNotificationId}/toggle_off`))
}
