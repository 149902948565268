const setViewportHeight = {
  mounted() {
    window.addEventListener('resize', this.setViewportHeight)
  },
  methods: {
    setViewportHeight() {
      document.body.style = 'height: 100vh;'

      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
  }
}

export default setViewportHeight
