<template>
  <div class="menu-wrap" v-show="showMenu">
    <div class="mask" @click="hide"></div>
    <div class="left-panel has-background-white">
      <img src="@assets/icons/close.svg" alt="close" class="close" @click="hide">
      <div class="links">
        <div>
          <span class="num has-text-blue has-text-weight-semibold">01</span>
          <span :class="`link has-text-weight-semibold ${$route.path === '/dashboard' && activeLink === 'dashboard' ? 'has-text-blue' : ''}`" @click="toDashboard">Dashboard</span>
        </div>
        <div>
          <span class="num has-text-blue has-text-weight-semibold">02</span>
          <span :class="`link has-text-weight-semibold ${$route.path === '/portfolio' ? 'has-text-blue' : ''}`" @click="toPortfolio">Portfolio</span>
        </div>
        <div>
          <span class="num has-text-blue has-text-weight-semibold">03</span>
          <span :class="`link has-text-weight-semibold ${$route.path === '/wallet' ? 'has-text-blue' : ''}`" @click="toWalletPage">addy Wallet</span>
        </div>
        <div>
          <span class="num has-text-blue has-text-weight-semibold">04</span>
          <span :class="`link has-text-weight-semibold ${$route.path === '/allProperties' ? 'has-text-blue' : ''}`" @click="toPropertyLandingPage">Properties</span>
        </div>
        <div>
          <span class="num has-text-blue has-text-weight-semibold">05</span>
          <span :class="`link has-text-weight-semibold ${$route.path === '/investorProfile' ? 'has-text-blue' : ''}`" @click="toProfilePage">Profile</span>
        </div>
      </div>
      <div class="logout has-text-centered">
        <span class="has-text-weight-semibold" @click="logout">Log out</span>
      </div>
      <div class="terms has-text-centered">
        <a href="https://www.addyinvest.com/terms-of-use/" target="_blank">Terms of Use</a>
      </div>
      <div class="website has-text-centered">
        <a href="https://www.addyinvest.com/" target="_blank">addyinvest.com</a>
      </div>
      <div class="media is-flex is-justify-content-space-between is-align-items-center">
        <img src="@assets/icons/media-Facebook.svg" alt="Facebook" @click="toMedia('https://www.facebook.com/addyinvest/')">
        <img src="@assets/icons/media-Twitter.svg" alt="Twitter" @click="toMedia('https://twitter.com/addyinvest')">
        <img src="@assets/icons/media-Instagram.svg" alt="Instagram" @click="toMedia('https://www.instagram.com/addyinvest/')">
        <img src="@assets/icons/media-YouTube.svg" alt="YouTube" @click="toMedia('https://www.youtube.com/channel/UCktiu1eisP2a24Er3vG7-zA')">
      </div>
    </div>
  </div>
</template>
<script>
import { logOutAndCleanSession } from '@utils/common-methods/auth'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      activeLink: 'dashboard',
    }
  },
  computed: {
    ...mapState(['showMenu', 'propertyBaseUrlOnMenu']),
  },
  watch: {
    showMenu(value) {
      const app = document.getElementById('app')
      if (value) {
        app.style = 'height: 100vh; overflow: hidden;'
        this.getActiveLink()
      } else {
        app.style = ''
      }
    },
  },
  methods: {
    logout() {
      logOutAndCleanSession().then(() => {
        this.hide()
        this.$router.push('/login')
      })
    },
    toMedia(url) {
      window.open(url)
    },
    toDashboard() {
      this.hide()
      if (this.$route.path !== '/dashboard') this.$router.push('/dashboard')
    },
    toPortfolio() {
      this.hide()
      if (this.$route.path !== '/portfolio') this.$router.push('/portfolio')
    },
    toWalletPage() {
      this.hide()
      if (this.$route.path !== '/wallet') this.$router.push('/wallet')
    },
    toPropertyLandingPage() {
      this.hide()
      if (this.$route.path !== '/allProperties') this.$router.push('/allProperties')
    },
    toProfilePage() {
      this.hide()
      if (this.$route.path !== '/investorProfile') this.$router.push('/investorProfile')
    },
    getActiveLink() {
      if (this.$route.path === '/dashboard') {
        this.activeLink = 'dashboard'
      }
    },
    hide() {
      this.$store.commit('toggleMenu', false)
    },
  },
}
</script>
<style lang="scss" scoped>
.menu-wrap {
  width: 100vw;
  height: 100vh;
  background-color: rgba(45, 41, 38, 0.25);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  overflow: auto;
  .mask {
    width: 100%;
    height: 100%;
  }
  .left-panel {
    position: absolute;
    left: 0;
    top: 0;
    width: 280px;
    min-height: 100%;
    .close {
      width: 20px;
      margin-top: 24px;
      margin-left: 27px;
    }
    .links {
      padding: 50px 0 72px 59px;
      > div {
        margin-bottom: 24px;
      }
      .num {
        font-size: 16px;
        line-height: 26px;
        padding-right: 12px;
        vertical-align: top;
      }
      .link {
        font-size: 28px;
        line-height: 33px;
      }
    }
    .logout {
      font-size: 16px;
    }
    .terms {
      font-size: 16px;
      margin-top: 42px;
    }
    .website {
      font-size: 16px;
      margin-top: 22px;
    }
    .media {
      padding: 25px 60px 140px;
      > img {
        width: 16px;
      }
    }
  }
}
</style>
