<template>
  <header id="desktop-header">
    <main class="is-flex is-justify-content-space-between is-align-items-center">
      <div id="desktop-header-left"></div>
      <TopIcons></TopIcons>
    </main>
  </header>
</template>
<script>
import TopIcons from '@components/desktop/top-icons.vue'

export default {
  components: {
    TopIcons,
  },
}
</script>
<style lang="scss" scoped>
#desktop-header {
  padding: 0 40PX;
  > main {
    height: 100%;
  }
}
</style>
