import axios from '@lib/axios/middleware'
import { getImbyDomain } from '@utils/common-methods/getImbyDomain'

const getImbyRoute = (url) => {
  if (window.location.hostname === 'localhost') return url
  return getImbyDomain() + url
}

/**
 * login
 * @param {Object} payload email, password
 * @return {Promise}
 */
export const auth = (payload) => {
  return axios.post(getImbyRoute('/api/v2/public/authorizations/sign_in'), payload)
}

/**
 * multi-factor authentication details
 * @param {Object} payload mfa_token
 * @return {Promise}
 */
export const mfaEnrollmentDetails = (payload) => {
  return axios.get(getImbyRoute('/api/v2/public/authorizations/mfa_enrollment_details'), payload)
}

/**
 * multi-factor authentication verify
 * @param {Object} payload otp
 * @return {Promise}
 */
export const mfaVerify = (payload) => {
  return axios.post(getImbyRoute('/api/v2/public/authorizations/mfa_verify'), payload)
}

/**
 * reset password
 * @param {Object} payload email
 * @return {Promise}
 */
export const resetPassword = (payload) => {
  return axios.post(getImbyRoute('/api/v2/public/authorizations/reset_password'), payload)
}

/**
 * log out
 * @return {Promise}
 */
export const clearSession = () => {
  return axios.post(getImbyRoute('/api/v2/authorizations/sign_out'))
}


/**
 * token from session
 * @return {Promise}
 */
export const getCookiesAccessPayload = () => {
  return axios.get(getImbyRoute('/api/v2/authorizations/cookies_access_payload'))
}

/**
 * confirm password for currently signed-in investor
 * @param {Object} payload {password_confirmation:}
 * @return {Promise}
 */

export const confirmPassword = (payload) => {
  return axios.post(getImbyRoute('/api/v2/authorizations/confirm_password'), payload)
}


