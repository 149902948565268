<template>
  <!-- use v-if on component to toggle -->
  <StandardModal
    :showModal="true"
    :closeModal="closeModal"
    :hasCloseIcon="false"
    :buttonTappedAction="closeModal"
    title="Disclaimer and Acknowledgement"
    buttonTitle="Acknowledge"
  >
    <div class="modal-text" v-html="modalContent"></div>
  </StandardModal>
</template>
<script>
import StandardModal from '@components/modal/standard-modal.vue'
import localModalContent from '@utils/data/disclaimer_modal_content_map.json'

export default {
  data() {
    return {
      modalContentMap: {},
    }
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  components: {
    StandardModal,
  },
  created() {
    this.getModalContentMap()
  },
  computed: {
    modalContent() {
      // currently still use the disclaimer json for fallback - can be removed later
      if (this.type === 'atlasOneQuestionnaireDisclaimer') return localStorage.getItem('dealer_disclaimer') || this.modalContentMap.atlasOneQuestionnaireDisclaimer?.html || ''
      return this.modalContentMap[this.type]?.html || ''
    },
  },
  methods: {
    getModalContentMap() {
      if (window.location.hostname === 'localhost') {
        this.modalContentMap = localModalContent
        return
      }

      fetch('https://addy-public.s3.us-west-2.amazonaws.com/disclaimer_modal_content_map.json', {
        cache: 'no-store'
      })
        .then((response) => response.json())
        .then((data) => {
          this.modalContentMap = data
        })
    },
    closeModal() {
      this.$emit('closeDisclaimerModal')
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

::v-deep .modal-content {
  overflow: hidden;
}
.modal-text {
  // scrolling on text for mobile, modal-header: 75px, modal-button: 65px
  max-height: calc(100% - 140px);
  overflow: auto;
  font-size: 16px;
  line-height: 19px;
  ::v-deep {
    b {
      font-weight: bold;
    }
    i {
      font-style: italic;
    }
    ul {
      list-style: unset;
    }
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .modal-text {
    // scrolling on text for desktop, modal-header: 45px, modal-button: 60px
    max-height: calc(100% - 105px);
  }
}
</style>
