import { getImbyDomain } from '@utils/common-methods/getImbyDomain'

const inStaticUrls = (api) => {
  const staticApiUrls = [
    '/api/v1/dashboard/properties_for_sale_base_url',
    '/api/v1/wallet/cancel_coin_order',
    '/api/v1/wallet/cancel_withdrawal',
    '/api/v1/wallet/withdraw_funds',
    '/api/v2/authorizations/confirm_password',
    '/api/v2/public/authorizations/reset_password',
    '/api/v2/public/authorizations/sign_in',
  ]
  if (window.location.hostname !== 'localhost') {
    const prefix = getImbyDomain()
    for (let index = 0; index < staticApiUrls.length; index++) {
      staticApiUrls[index] = `${prefix}${staticApiUrls[index]}`
    }
  }
  return staticApiUrls.includes(api)
}

const inDynamicUrls = (api) => {
  return api.match(/.*\/api\/v1\/properties\/[a-z0-9]+\/(region_supported|investments_summary|buys\/[a-z0-9]+\/status|buys)$/i)
}

/**
 * Determine of if the error flash should be disabled
 * @param {String} api    - required
 * @return {Boolean}
 */
export const errorFlashDisabled = (api) => {
  return inStaticUrls(api) || inDynamicUrls(api)
}
