export const getEnvironment = () => {
  switch (window.location.hostname) {
  case 'canada.addyinvest-integration.com':
  case 'app.addyinvest-integration.com':
    return 'integration'
  case 'canada.addyinvest-staging.com':
  case 'app.addyinvest-staging.com':
    return 'staging'
  case 'canada.addyinvest.com':
  case 'app.addyinvest.com':
    return 'production'
  default:
    return 'development'
  }
}
