<template>
  <section class="is-flex is-align-items-center is-relative top-icons-wrapper">
    <BannerNotification/>
    <div class="light-purple-button is-flex is-justify-content-center is-align-items-center" @click="toReferralPage">
      <img src="@assets/icons/gift.svg" alt="referral icon">
    </div>
    <div class="pipe"></div>
    <div
      class="profile-panel is-flex is-align-items-center"
      @mouseover="profilePanelActive = true"
      @mouseleave="profilePanelActive = false"
    >
      <div class="profile-icon light-purple-button is-flex is-justify-content-center is-align-items-end">
        <img src="@assets/images/common/profile-icon.svg" alt="avatar" class="avatar">
      </div>
      <img src="@assets/icons/arrow-up.svg" alt="arrow up" v-if="profilePanelActive">
      <img src="@assets/icons/arrow-down.svg" alt="arrow down" v-else>
    </div>
    <section
      class="pop-up has-background-white"
      v-show="profilePanelActive"
      @mouseover="profilePanelActive = true"
      @mouseleave="profilePanelActive = false"
    >
      <a href="/investorProfile" class="mb-2">Profile</a>
      <a href="/addyPlus/dashboard" class="mb-2" v-if="hasAddyPlusAccount">addy Business</a>
      <a data-testid="logout" @click="logout">Sign out</a>
    </section>
  </section>
</template>

<script>
import { logOutAndCleanSession } from '@utils/common-methods/auth'
import BannerNotification from '@components/desktop/header-banner-notification.vue'

export default {
  components: {
    BannerNotification,
  },
  data() {
    return {
      profilePanelActive: false,
    }
  },
  computed: {
    hasAddyPlusAccount() {
      return JSON.parse(localStorage.getItem('investor'))?.hasAddyPlusAccount
    },
  },
  methods: {
    toReferralPage() {
      this.$router.push('/referralDashboard')
    },
    logout() {
      logOutAndCleanSession().then(() => this.$router.push('/login'))
    },
  },
}
</script>

<style lang="scss" scoped>
.top-icons-wrapper {
  height: 100%;
  .light-purple-button {
    width: 42PX;
    height: 42PX;
    border-radius: 50%;
  }
  .pipe {
    width: 1PX;
    height: 14PX;
    background-color: #D3D2D7;
    margin: 0 16PX;
  }
  .profile-panel {
    height: 100%;
    padding-right: 6PX;
    .profile-icon {
      overflow: hidden;
      margin-right: 10PX;
      .avatar {
        width: 25PX;
      }
    }
  }
  .pop-up {
    position: absolute;
    right: 0;
    top: 68PX;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    font-size: 16PX;
    padding: 10PX 20PX 10PX 0;
    border-radius: 8PX;
    z-index: 100;
    > a {
      padding: 4PX 12PX;
      display: block;
    }
  }
}
</style>
