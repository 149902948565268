import { clearSession } from '@api/auth'

/**
 * Invalid JWT on server and clean JWT stored in localStorage
 * @return {Boolean}
 */
export const logOutAndCleanSession = () => {
  return new Promise((resolve, reject) => {
    clearSession().then((result) => {
      if (!result.success) return
      localStorage.removeItem('investor')
      localStorage.removeItem('membershipDetails')
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('disclaimer_shown')
      resolve()
    })
  })
}
