import { AccountInfo } from '@/types/addy_plus/corporations/wallet/AccountList'

interface State {
  walletBalance: number
  pendingFunds: number
  processingDepositTotal: number
  connectedAccounts: AccountInfo[]
}

export default {
  namespaced: true,
  state: (): State => {
    return {
      walletBalance: 0,
      pendingFunds: 0,
      processingDepositTotal: 0,
      connectedAccounts: [],
    }
  },
  mutations: {
    setWalletBalance(state: State, payload: number) {
      state.walletBalance = payload
    },
    setPendingFunds(state: State, payload: number) {
      state.pendingFunds = payload
    },
    setProcessingDepositTotal(state: State, payload: number) {
      state.processingDepositTotal = payload
    },
    setConnectedAccounts(state: State, payload: AccountInfo[]) {
      state.connectedAccounts = payload
    }
  },
}
