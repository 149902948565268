import VueRouter from './router'
import store from '../store'
import { getCookiesAccessPayload } from '@api/auth'
import { navigateWithRouteGuard } from '@utils/common-methods/navigateWithRouteGuard'
import axios from '@lib/axios/middleware'

const routes = [
  {
    path: '/addyPlus',
    alias: '/addy_business',
    name: 'addyPlusBeforeLogin',
    component: () => import(/* webpackChunkName: "addyPlusBeforeLogin" */ '../views/index/Index.vue'),
    redirect: '/addyPlus/login',
    children: [
      {
        path: '/addyPlus/adminInvite/create',
        name: 'addyPlusSignup',
        component: () => import(/* webpackChunkName: "addyPlusSignup" */ '../views/signup/Create.vue')
      },
      {
        path: '/addyPlus/login',
        name: 'addyPlusLogin',
        component: () => import(/* webpackChunkName: "addyPlusLogin" */ '../views/login/Index.vue')
      },
      {
        path: '/addyPlus/factorAuthentication',
        name: 'factorAuthentication',
        component: () => import(/* webpackChunkName: "factorAuthentication" */ '../views/login/FactorAuthentication.vue'),
      },
    ]
  },
  {
    path: '/addyPlusBase',
    name: 'addyPlusBase',
    component: () => import(/* webpackChunkName: "addyPlusBase" */ '../views/addy_plus/base/Index.vue'),
    redirect: '/addyPlus/login',
    children: [
      {
        path: '/addyPlus/adminInvite/accept',
        name: 'addyPlusAdminInviteAccept',
        component: () => import(/* webpackChunkName: "addyPlusAdminInviteAccept" */ '../views/addy_plus/AdminInvite.vue'),
      },
      {
        path: '/addyPlus/dashboard',
        name: 'addyPlusDashboard',
        component: () => import(/* webpackChunkName: "addyPlusDashboard" */ '../views/addy_plus/accounts/Index.vue'),
      },
      {
        path: '/addyPlus/account/new',
        alias: '/addyPlus/account/edit/:accountId',
        name: 'createOrEditAddyPlusAccount',
        component: () => import(/* webpackChunkName: "createOrEditAddyPlusAccount" */ '../views/addy_plus/accounts/CreateOrEdit.vue'),
      },
      {
        path: '/addyPlus/:accountId/settings',
        name: 'addyPlusAccountSettings',
        meta: {
          breadcrumb: 'Settings',
        },
        component: () => import(/* webpackChunkName: "addyPlusAccountSettings" */ '../views/addy_plus/accounts/settings/Index.vue'),
      },
      {
        path: '/addyPlus/:accountId/settings/accountDetails',
        name: 'addyPlusAccountSettingsAccountDetails',
        meta: {
          breadcrumb: 'Account Details',
        },
        component: () => import(/* webpackChunkName: "addyPlusAccountSettingsAccountDetails" */ '../views/addy_plus/accounts/settings/details/Index.vue'),
      },
      {
        path: '/addyPlus/:accountId/settings/administrators',
        name: 'addyPlusAccountSettingsAdministrators',
        meta: {
          breadcrumb: 'Users',
        },
        component: () => import(/* webpackChunkName: "addyPlusAccountSettingsAdministrators" */ '../views/addy_plus/accounts/settings/administrators/Index.vue'),
      },
      {
        path: '/addyPlus/:accountId/settings/administrators/:adminId',
        name: 'addyPlusAccountSettingsAdministrator',
        meta: {
          breadcrumb: 'Permissions',
        },
        component: () => import(/* webpackChunkName: "addyPlusAccountSettingsAdministrator" */ '../views/addy_plus/accounts/settings/administrators/Permissions.vue'),
      },
      {
        path: '/addyPlus/:accountId/settings/dealers',
        name: 'addyPlusAccountSettingsDealers',
        meta: {
          breadcrumb: 'Dealers',
        },
        component: () => import(/* webpackChunkName: "addyPlusAccountSettingsDealers" */ '../views/addy_plus/accounts/settings/administrators/Index.vue'),
      },
      {
        path: '/addyPlus/:accountId/settings/dealers/:adminId',
        name: 'addyPlusAccountSettingsDealer',
        meta: {
          breadcrumb: 'Permissions',
        },
        component: () => import(/* webpackChunkName: "addyPlusAccountSettingsDealer" */ '../views/addy_plus/accounts/settings/administrators/DealerPermissions.vue'),
      },
      {
        path: '/addyPlus/:accountId/settings/dealerDisclaimer',
        name: 'addyPlusAccountSettingsDisclaimer',
        meta: {
          breadcrumb: 'Disclaimer',
        },
        component: () => import(/* webpackChunkName: "addyPlusAccountSettingsDisclaimer" */ '../views/addy_plus/accounts/settings/disclaimer/Index.vue'),
      },
      {
        path: '/addyPlus/:accountId/corporations',
        name: 'addyPlusCorporations',
        meta: {
          breadcrumb: 'Entities',
        },
        component: () => import(/* webpackChunkName: "addyPlusCorporations" */ '../views/addy_plus/corporations/List.vue'),
      },
      {
        path: '/addyPlus/:accountId/corporations/new',
        name: 'addyPlusNewCorporation',
        meta: {
          breadcrumb: 'New Entity',
        },
        component: () => import(/* webpackChunkName: "addyPlusNewCorporation" */ '../views/addy_plus/corporations/New.vue'),
      },
      {
        path: '/addyPlus/:accountId/corporations/:corporationId',
        name: 'addyPlusCorporationOverview',
        meta: {
          breadcrumb: 'Corporation Overview',
          permissions: 'entity',
        },
        component: () => import(/* webpackChunkName: "addyPlusCorporationOverview" */ '../views/addy_plus/corporations/Corporation.vue'),
      },
      {
        path: '/addyPlus/:accountId/corporations/:corporationId/dataRoom',
        name: 'addyPlusCorporationDataRoom',
        meta: {
          breadcrumb: 'Data Room',
          permissions: 'entityDataRoom',
        },
        component: () => import(/* webpackChunkName: "addyPlusCorporationDataRoom" */ '../views/addy_plus/corporations/data_room/Index.vue'),
      },
      {
        path: '/addyPlus/:accountId/corporations/:corporationId/properties',
        name: 'addyPlusPropertiesList',
        meta: {
          breadcrumb: 'Properties',
          permissions: 'entityAssets',
        },
        component: () => import(/* webpackChunkName: "addyPlusPropertiesList" */ '../views/addy_plus/properties/List.vue'),
      },
      {
        path: '/addyPlus/:accountId/corporations/:corporationId/transactionLedger',
        name: 'addyPlusCorporationTransactionLedger',
        meta: {
          breadcrumb: 'Ledger',
          permissions: 'entityTransactionLedger',
        },
        component: () => import(/* webpackChunkName: "addyPlusCorporationTransactionLedger" */ '../views/addy_plus/corporations/csr/TransactionLedger.vue'),
      },
      {
        path: '/addyPlus/:accountId/corporations/:corporationId/csr',
        name: 'addyPlusCorporationCsr',
        meta: {
          breadcrumb: 'CSR',
          permissions: 'entityCsr',
        },
        component: () => import(/* webpackChunkName: "addyPlusCorporationCsr" */ '../views/addy_plus/corporations/csr/CSR.vue'),
      },
      {
        path: '/addyPlus/:accountId/corporations/:corporationId/wallet',
        name: 'addyPlusCorporationWallet',
        meta: {
          breadcrumb: 'Wallet',
          permissions: 'entityWallet',
        },
        component: () => import(/* webpackChunkName: "addyPlusCorporationWallet" */ '../views/addy_plus/corporations/wallet/Index.vue'),
      },
      {
        path: '/addyPlus/:accountId/corporations/:corporationId/accountBase',
        name: 'addyPlusCorporationAccountBase',
        component: () => import(/* webpackChunkName: "addyPlusCorporationAccountBase" */ '../views/addy_plus/corporations/wallet/accounts/AccountBase.vue'),
        children: [
          {
            path: '/addyPlus/:accountId/corporations/:corporationId/wallet/accounts/new',
            name: 'addyPlusCorporationBankAccountConnecting',
            meta: {
              breadcrumb: 'Connect bank account',
            },
            component: () => import(/* webpackChunkName: "addyPlusCorporationBankAccountConnecting" */ '../views/addy_plus/corporations/wallet/accounts/New.vue'),
          },
          {
            path: '/addyPlus/:accountId/corporations/:corporationId/wallet/accounts/confirmAccountDetails',
            name: 'addyPlusCorporationBankAccountConfirmation',
            meta: {
              breadcrumb: 'Connect bank account',
            },
            component: () => import(/* webpackChunkName: "addyPlusCorporationBankAccountConfirmation" */ '../views/addy_plus/corporations/wallet/accounts/ConfirmAccountDetails.vue'),
          },
        ],
      },
      {
        path: '/addyPlus/:accountId/corporations/:corporationId/fundsBase',
        name: 'addyPlusCorporationFundsBase',
        component: () => import(/* webpackChunkName: "addyPlusCorporationFundsBase" */ '../views/addy_plus/corporations/wallet/funds/FundsBase.vue'),
        children: [
          {
            path: '/addyPlus/:accountId/corporations/:corporationId/wallet/funds',
            name: 'addyPlusCorporationWalletAddFunds',
            meta: {
              breadcrumb: 'Add funds',
            },
            component: () => import(/* webpackChunkName: "addyPlusCorporationWalletAddFunds" */ '../views/addy_plus/corporations/wallet/funds/AddFunds.vue'),
          },
          {
            path: '/addyPlus/:accountId/corporations/:corporationId/wallet/withdraw',
            name: 'addyPlusCorporationWalletWithdrawFunds',
            meta: {
              breadcrumb: 'Withdraw funds',
            },
            component: () => import(/* webpackChunkName: "addyPlusCorporationWalletWithdrawFunds" */ '../views/addy_plus/corporations/wallet/funds/WithdrawFunds.vue'),
          },
        ],
      },
      {
        path: '/addyPlus/:accountId/corporations/:corporationId/wallet/transferFunds',
        name: 'addyPlusCorporationWalletTransferFunds',
        meta: {
          breadcrumb: 'Transfer funds',
        },
        component: () => import(/* webpackChunkName: "addyPlusCorporationWalletTransferFunds" */ '../views/addy_plus/corporations/wallet/funds/TransferFunds.vue'),
      },
      {
        path: '/addyPlus/:accountId/corporations/:corporationId/csr/uploadMultipleSuitabilityStatuses',
        name: 'uploadMultipleSuitabilityStatuses',
        component: () => import(/* webpackChunkName: "uploadMultipleSuitabilityStatuses" */ '../views/addy_plus/corporations/csr/UploadMultipleSuitabilityStatuses.vue'),
      },
      {
        path: '/addyPlus/:accountId/corporations/:corporationId/csr/externalInvestments/upload',
        name: 'addyPlusCorporationCsrUploadExternalInvestments',
        component: () => import(/* webpackChunkName: "addyPlusCorporationCsrUploadExternalInvestments" */ '../views/addy_plus/corporations/csr/external_investments/Upload.vue'),
      },
      {
        path: '/addyPlus/:accountId/corporations/:corporationId/executives',
        name: 'addyPlusCorporationExecutivesList',
        meta: {
          breadcrumb: 'Executives',
          permissions: 'entityExecutives',
        },
        component: () => import(/* webpackChunkName: "addyPlusCorporationExecutivesList" */ '../views/addy_plus/corporations/executives/Index.vue')
      },
      {
        path: '/addyPlus/:accountId/corporations/:corporationId/distributions',
        name: 'addyPlusCorporationDistributions',
        meta: {
          breadcrumb: 'Distributions',
          permissions: 'entityDistributions',
        },
        component: () => import(/* webpackChunkName: "addyPlusCorporationDistributions" */ '../views/addy_plus/corporations/distributions/List.vue'),
      },
      {
        path: '/addyPlus/:accountId/corporations/:corporationId/wallet/distributeFunds',
        name: 'addyPlusCorporationDistributeFunds',
        meta: {
          breadcrumb: 'Distribute funds',
          permissions: 'entityDistributions',
        },
        component: () => import(/* webpackChunkName: "addyPlusCorporationDistributeFunds" */ '../views/addy_plus/corporations/distributions/DistributeFunds.vue'),
      },
      {
        path: '/addyPlus/:accountId/corporations/:corporationId/properties/new',
        name: 'addyPlusNewProperty',
        meta: {
          breadcrumb: 'New Property',
          permissions: 'asset',
        },
        component: () => import(/* webpackChunkName: "addyPlusNewProperty" */ '../views/addy_plus/properties/new/Index.vue'),
      },
      {
        path: '/addyPlus/:accountId/corporations/:corporationId/properties/:propertyId',
        name: 'addyPlusPropertyOverview',
        meta: {
          breadcrumb: 'Property Overview',
          permissions: 'asset',
        },
        component: () => import(/* webpackChunkName: "addyPlusPropertyOverview" */ '../views/addy_plus/properties/Overview.vue'),
      },
      {
        path: '/addyPlus/:accountId/corporations/:corporationId/properties/:propertyId/dueDiligence',
        name: 'addyPlusPropertyDueDiligence',
        meta: {
          breadcrumb: 'Due Diligence',
          permissions: 'assetDueDiligence',
        },
        component: () => import(/* webpackChunkName: "addyPlusPropertyDueDiligence" */ '../views/addy_plus/properties/due_diligence/Index.vue'),
      },
      {
        path: '/addyPlus/:accountId/corporations/:corporationId/properties/:propertyId/updates',
        name: 'addyPlusPropertyUpdatesList',
        meta: {
          breadcrumb: 'Property Updates',
          permissions: 'assetUpdates',
        },
        component: () => import(/* webpackChunkName: "addyPlusPropertyUpdatesList" */ '../views/addy_plus/properties/updates/Index.vue'),
      },
      {
        path: '/addyPlus/:accountId/corporations/:corporationId/properties/:propertyId/updates/new',
        name: 'addyPlusPropertyUpdateNew',
        meta: {
          permissions: 'assetUpdates',
        },
        component: () => import(/* webpackChunkName: "addyPlusPropertyUpdateNew" */ '../views/addy_plus/properties/updates/New.vue'),
      },
      {
        path: '/addyPlus/:accountId/corporations/:corporationId/properties/:propertyId/updates/:updateId',
        name: 'addyPlusPropertyUpdateEdit',
        meta: {
          permissions: 'assetUpdates',
        },
        component: () => import(/* webpackChunkName: "addyPlusPropertyUpdateEdit" */ '../views/addy_plus/properties/updates/Edit.vue'),
      },
    ]
  },
]

const fallbackRoute = [
  {
    path: '*',
    name: '404',
    meta: {
      hideDesktopCommon: true,
      profileSetupExemption: true,
      notRestrictedByHoldingPen: true,
    },
    component: () => import(/* webpackChunkName: "nonExisting" */ '../views/index/NonExisting.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes: [
    ...routes,
    ...fallbackRoute,
  ]
})

const sessionExemptionList = [
  'addyPlusSignup',
  'addyPlusLogin',
  '404',
]

let mutateDesktopCommonTimer
router.beforeEach((to, from, next) => {
  // This is for the backend API to identify the origin of request
  axios.defaults.headers.common['X-Platform'] = 'Desktop'
  axios.defaults.headers.common['X-Resource'] = 'addyPlus'
  if (to.params.accountId) {
    axios.defaults.headers.common['X-AddyPlus-AccountId'] = to.params.accountId
  } else {
    delete axios.defaults.headers.common['X-AddyPlus-AccountId']
  }


  mutateDesktopCommonTimer && clearTimeout(mutateDesktopCommonTimer)
  mutateDesktopCommonTimer = setTimeout(() => {
    store.commit('mutateDesktopCommon', false)
  }, 400)

  if (sessionExemptionList.includes(to.name)) return next()

  if (to.name === 'factorAuthentication') {
    if (localStorage.getItem('mfa_token')) return next()
    if (localStorage.getItem('access_token')) return next({ path: '/addyPlus/dashboard' })
    return next({ path: '/addyPlus/login' })
  }

  // Force MFA Authentication if token exists
  if (localStorage.getItem('mfa_token')) return next({ path: '/addyPlus/factorAuthentication' })

  // If access token exists && needs_sso_refresh cookie is does not exists continue navigation with route guard
  if (localStorage.getItem('access_token') && !window.$cookies.isKey('needs_sso_refresh')) return navigateWithRouteGuard({ to, from, next })

  // use redirect_url instead of redirectUrl for login to differentiate from other redirectUrl in the url
  const path = to.name !== 'addyPlusLogin' ? `/addyPlus/login?redirect_url=${encodeURIComponent(to.fullPath)}` : '/addyPlus/login'

  // Fetch access payload from cookie access_payload in the backend
  getCookiesAccessPayload().then((res) => {
    if (res.success) {
      localStorage.setItem('access_token', res.data.accessToken)
      localStorage.setItem('refresh_token', res.data.refreshToken)
      navigateWithRouteGuard({ to, from, next })
    }
  }).catch((e) => {next({ path })})
})

router.afterEach(() => {
  window.scrollTo(0, 0)
  const scrollContainer = document.getElementById('addy-plus-main-container')
  scrollContainer && (scrollContainer.scrollTop = 0)
})

export default router
