import addyAvailableRegions from '../data/addy_available_regions'

/**
 * @return {Boolean}
 */
export const isIOS = () => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
}

/**
 * @return {Boolean}
 */
export const embeddedInIos = () => {
  return window.webkit && window.webkit.messageHandlers.closeWebview
}

/**
 * @return {Boolean}
 */
export const mobileCheck = () => {
  return (function(a) {
    /* eslint-disable-next-line */
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4))) return true
    else return false
  })(navigator.userAgent || navigator.vendor || window.opera)
}

/**
 * @return {Boolean}
 */
export const isAndroid = () => {
  const ua = navigator.userAgent.toLowerCase()
  return ua.indexOf('android') > -1
}

/**
 * @param {File} file
 * @param {Function} callback
 */
export const encodeFileAsUrl = (file, callback) => {
  const reader = new FileReader()
  reader.onloadend = () => {
    callback(reader.result)
  }
  reader.readAsDataURL(file)
}

/**
 * @return {Boolean}
 * @param {String} country
 * @param {String} province
 */
export const investorInAvailableRegion = (country, province) => {
  const availableRegions = addyAvailableRegions

  if (availableRegions.includes(province)) return true
  else return false
}

/**
 * @return {Object}
 * @param {String} base64
 */
export const base64ToArrayBuffer = (base64) => {
  const binaryString = window.atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes.buffer
}

export const addPdfJs = () => {
  const pdfJs = document.getElementById('pdfjs-script')
  if (!pdfJs) {
    const newScript = document.createElement('script')
    newScript.id = 'pdfjs-script'
    newScript.src = 'https://mozilla.github.io/pdf.js/build/pdf.js'
    document.body.appendChild(newScript)
  }
}

const buildFormData = (formData, data, parentKey) => {
  if (Object.prototype.toString.call(data) === '[object Object]') {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${camelToSnakeCase(key)}]` : camelToSnakeCase(key))
    })
  } else {
    const value = data == null ? '' : data
    formData.append(parentKey, value)
  }
}

/**
 * @return {Object}
 * @param {Object} nestedObject
 * @param {String} parentKey optional
 */
export const nestedObjectToFormdata = (nestedObject, parentKey) => {
  const formData = new FormData()
  buildFormData(formData, nestedObject, parentKey)
  return formData
}

/**
 * @return {String}
 * @param {Number} bytes
 * @param {Boolean} si
 * @param {Number} dp
 */
export const humanFileSize = (bytes, si = true, dp = 1) => {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = si ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)


  return bytes.toFixed(dp) + ' ' + units[u]
}

export const buildDiscordConnectUrl = () => {
  if (localStorage.getItem('access_token') && process.env.VUE_APP_DISCORD_CLIENT_ID) {
    const discordAuthUrl = new URL('https://discord.com/api/oauth2/authorize')
    discordAuthUrl.searchParams.append('client_id', process.env.VUE_APP_DISCORD_CLIENT_ID)
    discordAuthUrl.searchParams.append('redirect_uri', `${window.location.origin}/investorProfile?discord=connected`)
    discordAuthUrl.searchParams.append('response_type', 'code')
    discordAuthUrl.searchParams.append('scope', 'identify guilds.join')
    return discordAuthUrl.href
  }
}

/**
 * @param {String} elmntId
 * @param {Integer} additionalOffsetLeft
 */
export const setDraggableElement = (elmntId, additionalOffsetLeft = 0) => {
  /* eslint-disable-next-line */
  let deltaX = 0, deltaY = 0, currentX = 0, currentY = 0
  const elmnt = document.getElementById(elmntId)
  const parentElmnt = elmnt.parentElement

  elmnt.onmousedown = dragMouseDown

  /**
   * @param {Object} e
   */
  function dragMouseDown(e) {
    e = e || window.event
    e.preventDefault()
    // get the mouse cursor position at startup:
    currentX = e.clientX
    currentY = e.clientY
    document.onmouseup = closeDragElement
    document.onmousemove = elementDrag
  }

  /**
   * @param {Object} e
   */
  function elementDrag(e) {
    e = e || window.event
    e.preventDefault()
    // calculate the new cursor position:
    deltaX = currentX - e.clientX
    deltaY = currentY - e.clientY
    currentX = e.clientX
    currentY = e.clientY
    // set the element's new position:
    const parentW = parentElmnt.clientWidth
    const parentH = parentElmnt.clientHeight
    const thisW = elmnt.clientWidth
    const thisH = elmntId === 'certificate-wrap' ? elmnt.clientHeight + 26 : elmnt.clientHeight
    let topY = elmnt.offsetTop - deltaY
    let leftX = elmnt.offsetLeft - deltaX

    if (topY <= 0) topY = 0
    if (topY >= parentH - thisH) topY = parentH - thisH

    // additionalOffsetLeft when element has a child element with offset, ex: certificate on buy flow share page
    if (leftX <= additionalOffsetLeft) leftX = additionalOffsetLeft
    if (leftX >= parentW - thisW - additionalOffsetLeft) leftX = parentW - thisW - additionalOffsetLeft

    elmnt.style.top = topY + 'px'
    elmnt.style.left = leftX + 'px'
  }

  /**
   * @param {Object} e
   */
  function closeDragElement(e) {
    // stop moving when mouse button is released:
    document.onmouseup = null
    document.onmousemove = null
  }
}

/**
 * Capitalize the first letter of a given string: word => Word
 * @return {String}
 * @param {String} s
 */
export const capitalizeFirstLetter = (s) => {
  return s.replace(/^\w/, s.charAt(0).toUpperCase())
}

/**
 * capitalize first letter of each word: upper case => Upper Case
 * @return {String}
 * @param {String} str
 */
export const capitalizeFirstLetterOfEachWord = (str) => str.replace(/(^\w)|(\s\w)/g, (matched) => `${matched.toUpperCase()}`)

/**
 * convert camel case to snake case: camelCase => camel_case
 * @return {String}
 * @param {String} str
 */
export const camelToSnakeCase = (str) => str.replace(/[A-Z0-9]/g, (letter) => `_${letter.toLowerCase()}`)

/**
 * Is the user an American resident
 * @return {Boolean}
 */
export const isAmericanUser = () => {
  const investor = localStorage.getItem('investor')
  return investor && JSON.parse(investor).country === 'United States'
}

/**
 * Given a set of numeric values, returns a set of integer percents
 * Algorithm defined by Rahul Narain, translated to JavaScript.
 * http://math.stackexchange.com/questions/183476/rounding-of-complementary-percentages#186225
 * https://gist.github.com/robcolburn/3821231
 * @param {Array} values
 * @return {Array}
 */
export const roundPercents = (values) => {
  const n = values.length
  let sum = 0
  const s = 100
  let i = 0
  const p = new Array(n)
  const q = new Array(n)
  const r = new Array(n)
  let k = 0
  let deservingIndex = 0

  for (i = 0; i < n; i++) {
    sum += values[i]
  }

  for (i = 0; i < n; i++) {
    p[i] = s * (values[i] / sum)
    q[i] = Math.floor(p[i])
    r[i] = p[i] - q[i]
  }

  for (i = 0; i < n; i++) {
    k += r[i]
  }
  k = Math.round(k)

  while (k > 0) {
    deservingIndex = 0
    for (i = 0; i < n; i++) {
      if (boostLowValues(i) > boostLowValues(deservingIndex)) {
        deservingIndex = i
      }
    }
    for (i = 0; i < n; i++) {
      if (largestFraction(i) > largestFraction(deservingIndex)) {
        deservingIndex = i
      }
    }
    q[deservingIndex] += 1
    r[deservingIndex] = 0
    k--
  }
  return q

  /**
   * If you had values 33.3%, 33.3%, and 33.4%, then k=0.3+0.3+0.4=1, and the values of f are 0.3, 0.3, and 0.4, so you round as 33%, 33%, and 34%.
   * @param {Number} i
   * @return {Number}
   */
  function largestFraction(i) {
    return r[i]
  }

  /**
   * a value of, say, 2.xx% need only be at least 2.02% to beat 50.5% and 90.9% in the Who Wants to Be Rounded Up competition
   * @param {Number} i
   * @return {Number}
   */
  function boostLowValues(i) {
    return r[i] + (s - p[i]) / s
  }
}

/**
 * Return a legit url for redirect
 * @param {String} url
 * @return {String} return a legit redirect url
 */
export const legitRedirectUrl = (url) => {
  const landingPage = 'https://www.addyinvest.com/'
  if (!url) return landingPage

  const whiteList = [
    'https://canada.addyinvest-integration.com',
    'https://canada.addyinvest-staging.com',
    'https://canada.addyinvest.com',
    'https://app.addyinvest-integration.com',
    'https://app.addyinvest-staging.com',
    'https://app.addyinvest.com',
    'https://imby-integration.us.auth0.com',
    'https://imbystaging.auth0.com',
    'https://imby.auth0.com',
    'https://auth.addyinvest.com',
  ]
  const matchedOrigin = url.match(/^https.+\.com/)
  return (matchedOrigin && whiteList.includes(matchedOrigin[0])) ? url : landingPage
}

/**
 * @param {String} country
 * @return {Boolean}
 */
// TODO: need to update this method for holding pen logic
export const willRedirectToHoldingPen = (country) => {
  const isCanadian = country.toLowerCase() === 'canada'
  if (!process.env.VUE_APP_US_HOLDING_PEN) return !isCanadian
  return JSON.parse(process.env.VUE_APP_US_HOLDING_PEN) && !isCanadian
}

/**
 * download file
 * @param {String} url
 * @param {String} fileName
 */
export const downloadFile = (url, fileName) => {
  const xhr = new XMLHttpRequest()
  xhr.open('GET', url, true)
  xhr.responseType = 'blob'
  xhr.onload = function() {
    const urlCreator = window.URL || window.webkitURL
    const imageUrl = urlCreator.createObjectURL(this.response)
    const tag = document.createElement('a')
    tag.href = imageUrl
    tag.download = fileName
    document.body.appendChild(tag)
    tag.click()
    document.body.removeChild(tag)
  }
  xhr.send()
}

/**
 * download file from url
 * @param {String} url
 * @param {String} fileName
 */
export const downloadFileFromUrl = (url, fileName) => {
  const tag = document.createElement('a')
  tag.target = '_blank'
  tag.href = url
  tag.download = fileName
  document.body.appendChild(tag)
  tag.click()
  document.body.removeChild(tag)
}

/**
 * fetch and open a new tap to preview file (image or pdf)
 * @param {String} url
 */
export const previewFile = (url) => {
  const tag = document.createElement('a')
  tag.target = '_blank'
  tag.href = url
  document.body.appendChild(tag)
  tag.click()
  document.body.removeChild(tag)
}

/**
 * sort array in ascending order
 * @return {Array}
 * @param {Array} arr
 * @param {String} key - optional
 */
export const ascSort = (arr, key) => {
  return arr.sort((a, b) => {
    let cur = key ? a[key] : a
    cur = typeof cur === 'string' ? cur.toUpperCase() : cur

    let next = key ? b[key] : b
    next = typeof next === 'string' ? next.toUpperCase() : next

    if (cur < next) return -1
    else if (cur > next) return 1
    else return 0
  })
}
