import PhoneNumber from 'awesome-phonenumber'

const validateByCountry = (number, country) => {
  const phone = new PhoneNumber(number, country)
  return phone.isValid()
}

export default (extend) => {
  extend('phone-number', {
    validate: (value) => {
      return validateByCountry(value, 'CA') || validateByCountry(value, 'US')
    },
    message: 'Invalid phone number'
  })
}
