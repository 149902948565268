import VueRouter from './router'
import store from '../store'
import { getCookiesAccessPayload } from '@api/auth'
import { embeddedInIos, mobileCheck } from '@utils/common-methods/common'
import { handleDesktopRedirect } from '@utils/common-methods/handleDesktopRedirect'
import { navigateWithRouteGuard } from '@utils/common-methods/navigateWithRouteGuard'
import axios from '@lib/axios/middleware'

const routes = [
  {
    path: '/',
    name: 'addyIndex',
    // route level code-splitting
    // this generates a separate chunk (index.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "addyIndex" */ '../views/index/Index.vue'),
    redirect: '/login',
    children: [
      {
        path: '/signup/create',
        name: 'signup',
        meta: {
          hideDesktopCommon: true,
          profileSetupExemption: true,
          notRestrictedByHoldingPen: true,
        },
        component: () => import(/* webpackChunkName: "signup" */ '../views/signup/Create.vue')
      },
      {
        path: '/signup/regionNotAvailable',
        name: 'regionNotAvailableForSignup',
        meta: {
          hideDesktopCommon: true,
          profileSetupExemption: true,
          notRestrictedByHoldingPen: true,
        },
        component: () => import(/* webpackChunkName: "regionNotAvailable" */ '../views/index/RegionNotAvailable.vue')
      },
      {
        path: '/signup/verify',
        name: 'signupVerify',
        meta: {
          hideDesktopCommon: true,
          profileSetupExemption: true,
          notRestrictedByHoldingPen: true,
        },
        component: () => import(/* webpackChunkName: "signupVerify" */ '../views/signup/Verify.vue')
      },
      {
        path: '/signup/selectProvince',
        name: 'selectProvince',
        meta: {
          hideDesktopCommon: true,
          profileSetupExemption: true,
          notRestrictedByHoldingPen: true,
        },
        component: () => import(/* webpackChunkName: "selectProvince" */ '../views/signup/SelectProvince.vue')
      },
      {
        path: '/login',
        name: 'login',
        meta: {
          hideDesktopCommon: true,
          profileSetupExemption: true,
          notRestrictedByHoldingPen: true,
        },
        component: () => import(/* webpackChunkName: "login" */ '../views/login/Index.vue')
      },
    ]
  },
  {
    path: '/socialCallback',
    name: 'socialCallback',
    meta: {
      hideDesktopCommon: true,
      profileSetupExemption: true,
      notRestrictedByHoldingPen: true,
    },
    component: () => import(/* webpackChunkName: "socialCallback" */ '../views/signup/SocialCallback.vue')
  },
  {
    path: '/verifyEmailWithAuth0',
    name: 'verifyEmailWithAuth0',
    meta: {
      hideDesktopCommon: true,
      profileSetupExemption: true,
      notRestrictedByHoldingPen: true,
    },
    component: () => import(/* webpackChunkName: "verifyEmailWithAuth0" */ '../views/signup/VerifyEmailWithAuth0.vue')
  },
  {
    path: '/emailVerification',
    name: 'emailVerification',
    meta: {
      hideDesktopCommon: true,
      profileSetupExemption: true,
      notRestrictedByHoldingPen: true,
    },
    component: () => import(/* webpackChunkName: "emailVerification" */ '../views/signup/EmailVerification.vue')
  },
  {
    path: '/signup',
    name: 'onboardingBase',
    component: () => import(/* webpackChunkName: "onboardingBase" */ '../views/index/OnboardingBase.vue'),
    redirect: '/signup/profile/1',
    children: [
      {
        path: '/signup/profile/:step',
        name: 'signupProfile',
        meta: {
          hideDesktopCommon: true,
          profileSetupExemption: true,
          notRestrictedByHoldingPen: true,
        },
        component: () => import(/* webpackChunkName: "signupProfile" */ '../views/signup/Profile.vue')
      },
      {
        path: '/signup/address/:step',
        name: 'signupAddress',
        meta: {
          hideDesktopCommon: true,
          profileSetupExemption: true,
          notRestrictedByHoldingPen: true,
        },
        component: () => import(/* webpackChunkName: "signupAddress" */ '../views/signup/Address.vue')
      },
      {
        path: '/signup/wallet',
        name: 'signupWallet',
        meta: {
          hideDesktopCommon: true,
          profileSetupExemption: true,
        },
        component: () => import(/* webpackChunkName: "signupWallet" */ '../views/signup/Wallet.vue')
      },
      {
        path: '/signup/questionnaire',
        name: 'signupQuestionnaire',
        meta: {
          hideDesktopCommon: true,
          profileSetupExemption: true,
          notRestrictedByHoldingPen: true,
        },
        component: () => import(/* webpackChunkName: "signupQuestionnaire" */ '../views/signup/AmericanQuestionnaire.vue')
      },
    ]
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/Index.vue')
  },
  {
    path: '/walletBase',
    name: 'walletBase',
    component: () => import(/* webpackChunkName: "walletBase" */ '../views/wallet/Index.vue'),
    children: [
      {
        path: '/wallet/accounts/new',
        name: 'connectBankAccount',
        component: () => import(/* webpackChunkName: "connectBankAccount" */ '../views/wallet/accounts/ConnectBankAccount.vue')
      },
      {
        path: '/wallet/accounts/confirmBankDetailsThroughPlaid',
        name: 'confirmBankDetailsThroughPlaid',
        component: () => import(/* webpackChunkName: "confirmBankDetailsThroughPlaid" */ '../views/wallet/accounts/ConfirmBankDetails.vue')
      },
      {
        path: '/wallet/accounts/padAgreement',
        name: 'padAgreement',
        component: () => import(/* webpackChunkName: "padAgreement" */ '../views/wallet/accounts/PadAgreement.vue')
      },
      {
        path: '/wallet/accounts/done',
        name: 'accountConnected',
        component: () => import(/* webpackChunkName: "accountConnected" */ '../views/wallet/accounts/AccountConnected.vue')
      },
      {
        path: '/wallet/accounts/manual',
        name: 'preparationForManualConnection',
        component: () => import(/* webpackChunkName: "preparationForManualConnection" */ '../views/wallet/accounts/manual/Preparation.vue')
      },
      {
        path: '/wallet/accounts/manual/bankAccount',
        name: 'selectBankForManualConnection',
        component: () => import(/* webpackChunkName: "selectBankForManualConnection" */ '../views/wallet/accounts/manual/Bank.vue')
      },
      {
        path: '/wallet/accounts/manual/accountInfo',
        name: 'accountInfoForManualConnection',
        component: () => import(/* webpackChunkName: "accountInfoForManualConnection" */ '../views/wallet/accounts/manual/Account.vue')
      },
      {
        path: '/wallet/accounts/manual/cheque',
        name: 'uploadChequeForManualConnection',
        component: () => import(/* webpackChunkName: "uploadChequeForManualConnection" */ '../views/wallet/accounts/manual/Cheque.vue')
      },
      {
        path: '/wallet/options',
        name: 'addFundsOptions',
        component: () => import(/* webpackChunkName: "addFundsOptions" */ '../views/wallet/funds/Options.vue')
      },
      {
        path: '/wallet/interac',
        name: 'interac',
        component: () => import(/* webpackChunkName: "interac" */ '../views/wallet/funds/Interac.vue')
      },
      {
        path: '/wallet/funds',
        name: 'addFunds',
        component: () => import(/* webpackChunkName: "addFunds" */ '../views/wallet/funds/AddFunds.vue')
      },
      {
        path: '/wallet/fundsSuccess',
        name: 'successForAddFunds',
        component: () => import(/* webpackChunkName: "addFundsSuccess" */ '../views/wallet/funds/AddFundsSuccess.vue')
      },
      {
        path: '/wallet/wise',
        name: 'addUsdThroughWise',
        component: () => import(/* webpackChunkName: "addUsdThroughWise" */ '../views/wallet/funds/AddUsdThroughWise.vue')
      },
      {
        path: '/wallet/withdraw',
        name: 'withdrawFunds',
        component: () => import(/* webpackChunkName: "withdrawFunds" */ '../views/wallet/funds/WithdrawFunds.vue')
      },
      {
        path: '/wallet/withdrawalSuccess',
        name: 'successForWithdrawal',
        component: () => import(/* webpackChunkName: "withdrawalSuccess" */ '../views/wallet/funds/WithdrawalSuccess.vue')
      },
    ]
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import(/* webpackChunkName: "wallet" */ '../views/wallet/landing/Index.vue')
  },
  {
    path: '/referralDashboard',
    name: 'referralDashboard',
    meta: {
      notRestrictedByHoldingPen: true,
    },
    component: () => import(/* webpackChunkName: "referralDashboard" */ '../views/referral/Index.vue')
  },
  {
    path: '/referralGateway',
    alias: '/referral_gateway',
    name: 'referralGateway',
    meta: {
      hideDesktopCommon: true,
      profileSetupExemption: true,
      notRestrictedByHoldingPen: true,
    },
    component: () => import(/* webpackChunkName: "referralGateway" */ '../views/referral/landing/Index.vue')
  },
  {
    path: '/investorProfile',
    name: 'investorProfile',
    component: () => import(/* webpackChunkName: "investorProfile" */ '../views/profile/Index.vue')
  },
  {
    path: '/investorProfile/kyc',
    name: 'knowYourCustomer',
    component: () => import(/* webpackChunkName: "knowYourCustomer" */ '../views/profile/kyc/Index.vue')
  },
  {
    path: '/investorProfile/governmentId',
    name: 'governmentIdUploadBase',
    redirect: '/investorProfile/governmentId/upload',
    component: () => import(/* webpackChunkName: "verifyMemberAccount" */ '../views/profile/upload_id/Index.vue'),
    children: [
      {
        path: '/investorProfile/governmentId/upload',
        name: 'governmentIdUpload',
        component: () => import(/* webpackChunkName: "verifyMemberAccount" */ '../views/profile/upload_id/Upload.vue')
      },
      {
        path: '/investorProfile/governmentId/verify',
        name: 'governmentIdVerify',
        component: () => import(/* webpackChunkName: "verifyMemberAccount" */ '../views/profile/upload_id/Verify.vue')
      },
      {
        path: '/investorProfile/governmentId/success',
        name: 'governmentIdSuccess',
        component: () => import(/* webpackChunkName: "verifyMemberAccount" */ '../views/profile/upload_id/Success.vue')
      },
    ]
  },
  {
    path: '/investorProfile/sinInput',
    name: 'sinInputBase',
    redirect: '/investorProfile/sinInput/upload',
    component: () => import(/* webpackChunkName: "submitTaxDocuments" */ '../views/profile/uploadSin/Index.vue'),
    children: [
      {
        path: '/investorProfile/sinInput/upload',
        name: 'sinInputUpload',
        component: () => import(/* webpackChunkName: "submitTaxDocuments" */ '../views/profile/uploadSin/Upload.vue')
      },
      {
        path: '/investorProfile/sinInput/success/:docsAvailable',
        name: 'sinInputBaseSuccess',
        component: () => import(/* webpackChunkName: "submitTaxDocuments" */ '../views/profile/uploadSin/Success.vue')
      },
    ]
  },
  {
    path: '/investorProfile/taxDocs',
    name: 'taxDocs',
    component: () => import(/* webpackChunkName: "submitTaxDocuments" */ '../views/profile/uploadSin/TaxDocuments.vue')
  },
  {
    path: '/investorProfile/suitabilityQuestionnaire',
    name: 'suitabilityQuestionnaire',
    component: () => import(/* webpackChunkName: "suitabilityQuestionnaire" */ '../views/profile/questionnaire/Suitability.vue')
  },
  {
    path: '/investorProfile/eligibilityQuestionnaire',
    name: 'eligibilityQuestionnaire',
    component: () => import(/* webpackChunkName: "eligibilityQuestionnaire" */ '../views/profile/questionnaire/Eligibility.vue')
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: () => import(/* webpackChunkName: "portfolio" */ '../views/portfolio/index/Index.vue')
  },
  {
    path: '/portfolio/:nameSpaceUrl/property/:propertyId',
    name: 'investmentDetails',
    component: () => import(/* webpackChunkName: "investmentDetails" */ '../views/portfolio/index/InvestmentDetails.vue')
  },
  {
    path: '/portfolio/:nameSpaceUrl/property/:propertyId/propertyUpdate/:updateId',
    name: 'propertyUpdate',
    component: () => import(/* webpackChunkName: "propertyUpdate" */ '../views/portfolio/index/PropertyUpdate.vue')
  },
  {
    path: '/portfolio/:nameSpaceUrl/share',
    name: 'sharePortfolio',
    component: () => import(/* webpackChunkName: "sharePortfolio" */ '../views/portfolio/share/Index.vue')
  },
  {
    path: '/portfolio/publicPortfolio',
    name: 'publicPortfolio',
    meta: {
      hideDesktopCommon: true,
      profileSetupExemption: true,
      notRestrictedByHoldingPen: true,
    },
    component: () => import(/* webpackChunkName: "publicPortfolio" */ '../views/portfolio/share/PublicPortfolio.vue')
  },
  {
    path: '/membership/options',
    name: 'membershipSelection',
    component: () => import(/* webpackChunkName: "membershipSelection" */ '../views/membership/Options.vue')
  },
  {
    path: '/membership/stripeCheckout/:type',
    name: 'membershipStripeCheckout',
    component: () => import(/* webpackChunkName: "membershipStripeCheckout" */ '../views/membership/Stripe.vue')
  },
  {
    path: '/membership',
    name: 'membershipBase',
    redirect: '/membership/confirmation/addyOne',
    component: () => import(/* webpackChunkName: "membershipBase" */ '../views/membership/Index.vue'),
    children: [
      {
        path: '/membership/accredited/paragraphs',
        name: 'accreditedInvestorParagraphs',
        component: () => import(/* webpackChunkName: "accreditedInvestorParagraphs" */ '../views/membership/Paragraphs.vue')
      },
      {
        path: '/membership/confirmation/:type',
        name: 'membershipConfirmation',
        component: () => import(/* webpackChunkName: "membershipConfirmation" */ '../views/membership/Confirmation.vue')
      },
      {
        path: '/membership/success',
        name: 'membershipPaid',
        component: () => import(/* webpackChunkName: "membershipPaid" */ '../views/membership/Success.vue')
      },
    ]
  },
  {
    path: '/allProperties',
    name: 'allProperties',
    component: () => import(/* webpackChunkName: "allProperties" */ '../views/properties/all_properties/Index.vue')
  },
  {
    path: '/:nameSpaceUrl/buy/:propertyId',
    name: 'buyProperty',
    component: () => import(/* webpackChunkName: "buyProperty" */ '../views/properties/buy/BuyProperty.vue')
  },
  {
    path: '/buy',
    name: 'buyBase',
    component: () => import(/* webpackChunkName: "buy" */ '../views/properties/buy/Index.vue'),
    children: [
      {
        path: '/buy/residency/:propertyId', // this page is currently deprecated
        name: 'confirmResidency',
        component: () => import(/* webpackChunkName: "buy" */ '../views/properties/buy/Residency.vue')
      },
      {
        path: '/:nameSpaceUrl/buy/:propertyId/incomeReporting',
        name: 'americanIncomeReporting',
        component: () => import(/* webpackChunkName: "buy" */ '../views/properties/buy/AmericanIncomeReporting.vue')
      },
      {
        path: '/:nameSpaceUrl/buy/amount/:propertyId',
        name: 'chooseAmount',
        component: () => import(/* webpackChunkName: "buy" */ '../views/properties/buy/Amount.vue')
      },
      {
        path: '/:nameSpaceUrl/buy/confirm/:propertyId',
        name: 'investmentConfirmation',
        component: () => import(/* webpackChunkName: "buy" */ '../views/properties/buy/Confirm.vue')
      },
      {
        path: '/:nameSpaceUrl/buy/sign/:propertyId',
        name: 'signSubAgreement',
        component: () => import(/* webpackChunkName: "buy" */ '../views/properties/buy/Sign.vue')
      },
    ],
  },
  {
    path: '/:nameSpaceUrl/buy/share/:propertyId/:buyId',
    name: 'sharePurchase',
    component: () => import(/* webpackChunkName: "buy" */ '../views/properties/buy/Share.vue')
  },
  {
    path: '/regionNotAvailable',
    name: 'regionNotAvailable',
    component: () => import(/* webpackChunkName: "regionNotAvailable" */ '../views/index/RegionNotAvailable.vue')
  },
  {
    path: '/d',
    name: 'desktopBase',
    component: () => import(/* webpackChunkName: "desktopBase" */ '../views/desktop/Base.vue'),
    redirect: '/d/dashboard',
    children: [
      {
        path: '/d/dashboard',
        name: 'desktopDashboard',
        component: () => import(/* webpackChunkName: "desktopDashboard" */ '../views/desktop/dashboard/Index.vue'),
      }
    ]
  },
  {
    path: '/comingSoon',
    name: 'holdingPen',
    meta: {
      hideDesktopCommon: true,
      notRestrictedByHoldingPen: true,
    },
    component: () => import(/* webpackChunkName: "holdingPen" */ '../views/us/holding_pen/Index.vue')
  },
]

const fallbackRoute = [
  {
    path: '*',
    name: '404',
    meta: {
      hideDesktopCommon: true,
      profileSetupExemption: true,
      notRestrictedByHoldingPen: true,
    },
    component: () => import(/* webpackChunkName: "nonExisting" */ '../views/index/NonExisting.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes: [
    ...routes,
    ...fallbackRoute,
  ]
})

const sessionExemptionList = [
  'signup',
  'login',
  'publicPortfolio',
  'referralGateway',
  'emailVerification',
  'verifyEmailWithAuth0',
  '404',
]

let mutateDesktopCommonTimer
router.beforeEach((to, from, next) => {
  let platform = 'Desktop'
  if (embeddedInIos()) {
    platform = 'iOS'
  } else if (mobileCheck()) {
    platform = 'Mobile'
  }
  // This is for the backend API to identify the origin of request
  axios.defaults.headers.common['X-Platform'] = platform
  axios.defaults.headers.common['X-Resource'] = 'addyinvest'

  mutateDesktopCommonTimer && clearTimeout(mutateDesktopCommonTimer)
  const timeout = (!from.meta.hideDesktopCommon && to.name === 'login') ? 0 : 400
  mutateDesktopCommonTimer = setTimeout(() => {
    store.commit('mutateDesktopCommon', !to.meta.hideDesktopCommon)
  }, timeout)

  if (sessionExemptionList.includes(to.name)) return next()

  // don't call next() twice
  if (handleDesktopRedirect({ to, from, next })) return

  // If access token exists && needs_sso_refresh cookie is does not exists continue navigation with route guard
  if (localStorage.getItem('access_token') && !window.$cookies.isKey('needs_sso_refresh')) return navigateWithRouteGuard({ to, from, next })

  // use redirect_url instead of redirectUrl for login to differentiate from other redirectUrl in the url
  const path = to.name !== 'login' ? `/login?redirect_url=${encodeURIComponent(to.fullPath)}` : '/login'

  // Fetch access payload from cookie access_payload in the backend
  getCookiesAccessPayload().then((res) => {
    if (res.success) {
      if (embeddedInIos()) {
        window.webkit.messageHandlers.syncAccessPayload.postMessage(res.data)
        // Checks if SSO is succesfully redirected to dashboard path with SSO source
        // then close web view container of the native app
        if (window.$cookies.isKey('needs_sso_refresh') && to.name === 'dashboard') {
          // Remove cookie on close of web view container
          window.$cookies.remove('needs_sso_refresh')
          window.webkit.messageHandlers.closeWebview.postMessage(true)
        }
      }
      localStorage.setItem('access_token', res.data.accessToken)
      localStorage.setItem('refresh_token', res.data.refreshToken)
      navigateWithRouteGuard({ to, from, next })
    }
  }).catch((e) => {next({ path })})
})

router.afterEach((to, from) => {
  window.scrollTo(0, 0)
  const desktopScrollContainer = document.getElementById('desktop-scroll-container')
  desktopScrollContainer && (desktopScrollContainer.scrollTop = 0)
})

export default router
