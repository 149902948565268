import Vue from 'vue'
import Vuex from 'vuex'
import sample from './modules/sample'
import mixpanel from './modules/mixpanel'
import taxes from './modules/taxes'
import addyPlusBase from './modules/addyPlusBase'
import addyPlusDataRoom from './modules/addyPlusDataRoom'
import addPlusEntityFunds from './modules/addyPlusEntityFunds'

Vue.use(Vuex)

export default new Vuex.Store({
  state: { // only global variables live in here, the rest should go to modules
    showLoadingMask: false,
    showMenu: false,
    showDesktopCommon: false,
    propertyBaseUrlOnMenu: '',
    flipperToggles: {},
    membershipDetails: {},
    showLoginDisclaimer: false,
  },
  mutations: {
    toggleLoadingMask(state, payload) {
      state.showLoadingMask = payload
    },
    toggleMenu(state, payload) {
      state.showMenu = payload
    },
    mutateDesktopCommon(state, payload) {
      state.showDesktopCommon = payload
    },
    setPropertyBaseUrlOnMenu(state, payload) {
      state.propertyBaseUrlOnMenu = payload
    },
    setFlipperToggles(state, payload) {
      state.flipperToggles = payload
    },
    setMembershipDetails(state, payload) {
      state.membershipDetails = payload
    },
    toggleLoginDisclaimer(state, payload) {
      state.showLoginDisclaimer = payload
    },
  },
  modules: {
    sample,
    mixpanel,
    taxes,
    addyPlusBase,
    addyPlusDataRoom,
    addPlusEntityFunds,
  }
})
