import Vue from 'vue'
import Buefy from 'buefy'
import '../assets/style/base.scss'
import veeValidate from '../lib/vee-validate'
import '../utils/common-methods/rem'
import VueClipboard from 'vue-clipboard2'
import VueCookies from 'vue-cookies'
import { insertLib } from '../utils/common-methods/intercom'
import { mobileCheck } from '../utils/common-methods/common'

insertLib()

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV !== 'production'
Vue.use(Buefy, { defaultIconPack: 'fa' })
veeValidate(Vue)
Vue.use(VueClipboard)
Vue.use(VueCookies)
Vue.prototype.$isMobile = mobileCheck()

export default Vue
