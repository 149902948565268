<template>
  <div id="desktop-footer">
    <a href="https://www.addyinvest.com/terms-of-use/" target="_blank" class="terms has-text-weight-semibold">Terms of Use</a>
    <span class="company-name">Copyright © {{currentYear}} addy Technology Corp.</span>
    <div class="is-flex is-align-items-center social-buttons">
      <a
        :href="medium.link"
        target="_blank"
        v-for="(medium, index) in media"
        :key="index"
      >
        <img :src="`https://addy-public.s3.us-west-2.amazonaws.com/desktop-sm-${medium.medium}.svg`" :alt="medium.medium">
      </a>
    </div>
  </div>
</template>
<script>
import { buildDiscordConnectUrl } from '@utils/common-methods/common'
const MEDIA = [
  {
    medium: 'facebook',
    link: 'https://www.facebook.com/addyinvest/',
  },
  {
    medium: 'instagram',
    link: 'https://www.instagram.com/addyinvest/',
  },
  {
    medium: 'twitter',
    link: 'https://twitter.com/addyinvest',
  },
  {
    medium: 'linkedin',
    link: 'https://www.linkedin.com/company/addyinvest/',
  },
  {
    medium: 'youtube',
    link: 'https://www.youtube.com/channel/UCktiu1eisP2a24Er3vG7-zA',
  },
  {
    medium: 'tiktok',
    link: 'https://www.tiktok.com/@addyinvest',
  },
]

export default {
  data() {
    return {
      discordConnectUrl: '',
    }
  },
  props: {
    showDiscord: {
      type: Boolean,
      default: true
    },
  },
  created() {
    this.showDiscord && this.initializeDiscord()
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
    media() {
      return this.showDiscord ?
        [
          ...MEDIA,
          {
            medium: 'discord',
            link: this.discordConnectUrl
          }
        ] :
        MEDIA
    },
  },
  methods: {
    initializeDiscord() {
      this.discordConnectUrl = buildDiscordConnectUrl() || ''
    },
  },
}
</script>
<style lang="scss" scoped>
#desktop-footer {
  font-size: 14PX;
  width: 100%;
  justify-content: space-between;
  padding: 60PX 80PX 20PX;
  display: flex;
  .terms {
    color: #9185AE;
  }
  .company-name {
    color: #9185AE;
  }
  .social-buttons {
    a:not(:last-child) {
      padding-right: 25px;
    }
  }
}
</style>
