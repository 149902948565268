export default {
  namespaced: true,
  state: () => {
    return {
      sampleProp: ''
    }
  },
  getters: {
    parsedSamplePro(state) {
      return state.sampleProp * 2
    }
  },
  /**
   * To use in components:
   * <Option A>  ...mapMutations('sample', ['setSampleProp'])
   * <Option B>  this.$store.commit('sample/setSampleProp', payload)
   */
  mutations: {
    setSampleProp(state, payload) {
      state.sampleProp = payload
    }
  },
  /**
   * To use in components:
   * <Option A>  ...mapActions('sample', ['setSampleProp'])
   * <Option B>  this.$store.dispatch('sample/setSampleProp', payload)
   */
  actions: {
    setSampleProp({ commit }, payload) {
      commit('setSampleProp', payload)
    }
  }
}
