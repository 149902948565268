import axios from 'axios'
import store from '@/store'
import personalRouter from '@/router/personal'
import businessRouter from '@/router/business'
import { ToastProgrammatic as Toast } from 'buefy'
import camelize from 'camelize'
import { getImbyDomain } from '@utils/common-methods/getImbyDomain'
import { errorFlashDisabled } from '@utils/common-methods/noErrorFlashAxios'

import localStorageServiceForAuth from '@utils/common-methods/localStorageServiceForAuth'
const localStorageService = localStorageServiceForAuth()
let refreshEndpointUrl = '/api/v2/public/authorizations/refresh_access_token'
let getTokenEndpointUrl = '/auth/auth0/token'
if (window.location.hostname !== 'localhost') {
  refreshEndpointUrl = `${getImbyDomain()}${refreshEndpointUrl}`
  getTokenEndpointUrl = `${getImbyDomain()}${getTokenEndpointUrl}`
}
let refreshTokenPromise = null

axios.defaults.withCredentials = true
axios.defaults.headers.common['Cache-Control'] = 'no-cache'

// request interceptor
axios.interceptors.request.use(function(config) {
  if (config.useMask) store.commit('toggleLoadingMask', true)
  if (config.data instanceof FormData) { // set request header for file uploading
    Object.assign(config.headers, {
      'Content-Type': 'multipart/form-data'
    })
  }

  const token = localStorageService.getAccessToken()
  // if sso redirect, don't rely on localStorage token, could be stale
  // if this header is not present, the backend will fall back on access_token session cookie (http-only)
  if (token && !window.$cookies.isKey('needs_sso_refresh')) config.headers.Authorization = 'Bearer ' + token

  return config
}, function(error) {
  return Promise.reject(error)
})

// response interceptor
axios.interceptors.response.use(function(response) {
  if (response.config.useMask) store.commit('toggleLoadingMask', false)
  if (response.data.success !== undefined && response.data.success === false && !errorFlashDisabled(response.config.url) && !response.config.noErrorFlash) {
    Toast.open({
      message: response.data.error,
      duration: 3000,
      type: 'is-danger',
    })
  }
  return response.config.downloadFile ? response.data : camelize(response.data)
}, function(error) {
  if (error.response.data.error === 'mfa_required') return Promise.reject(error)

  const originalRequest = error.config
  const fetchTokens = originalRequest.url === refreshEndpointUrl || originalRequest.url === getTokenEndpointUrl
  const accountFrozen = error.response.data.account_frozen
  if (
    (error.response.status === 401 || error.response.status === 403) &&
    (fetchTokens || accountFrozen)
  ) {
    if (personalRouter.app) personalRouter.replace('/login')
    if (businessRouter.app && businessRouter.history.current.path !== '/addyPlus/login') businessRouter.replace('/addyPlus/login')

    localStorageService.clearToken()
    refreshTokenPromise = null
    return Promise.reject(error)
  }

  if (!localStorage.getItem('refresh_token')) return Promise.reject(error)

  if (error.response.status === 401 || error.response.status === 403) {
    if (refreshTokenPromise) {
      return refreshTokenPromise.then(() => {
        return axios(originalRequest)
      })
    } else {
      const refreshToken = localStorageService.getRefreshToken()
      refreshTokenPromise = axios.post(refreshEndpointUrl, {
        refresh_token: refreshToken
      })
      return refreshTokenPromise.then((res) => {
        if (res.success) {
          localStorageService.setToken(res.data)
          axios.defaults.headers.common.Authorization = 'Bearer ' + localStorageService.getAccessToken()
          refreshTokenPromise = null
          return axios(originalRequest)
        }
      })
    }
  }

  Toast.open({
    message: String(error),
    duration: 3000,
    type: 'is-danger',
  })

  return Promise.reject(error)
})

export default axios
