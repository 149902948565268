export default {
  namespaced: true,
  state: () => {
    return {
      taxDocs: [],
      hasTaxDocs: false, // lazy
      taxDocsAuthRefreshTimestamp: new Date().getTime(),
      electronicReception: true,
      sinSubmitted: false
    }
  },
  mutations: {
    setHasTaxDocs(state, payload) {
      state.hasTaxDocs = payload
    },
    setTaxDocs(state, payload) {
      state.taxDocs = payload
    },
    setTaxDocsAuthRefreshTimestamp(state, payload) {
      state.taxDocsAuthRefreshTimestamp = payload
    },
    setElectronicReception(state, payload) {
      state.electronicReception = payload
    },
    setSinSubmitted(state, payload) {
      state.sinSubmitted = payload
    }
  },
}
