import axios from '@lib/axios/middleware'
import { getImbyDomain } from '@utils/common-methods/getImbyDomain'

const getImbyRoute = (url) => {
  if (window.location.hostname === 'localhost') return url
  return getImbyDomain() + url
}

/**
 * signup
 * @param {Object} payload email, password, country, stateOrProvince
 * @return {Promise}
 */
export const signup = (payload) => {
  return axios.post(getImbyRoute('/api/v2/public/authorizations/sign_up'), payload)
}

/**
 * signup
 * @param {Object} payload email, password, country, stateOrProvince
 * @return {Promise}
 */
export const signupAddyPlusAdmin = (payload) => {
  return axios.post(getImbyRoute('/api/v2/public/authorizations/sign_up_addy_plus_admin'), payload)
}

/**
 * update investor profile
 * @param {Object} payload addy_handle, first_name, last_name, ...etc.
 * @return {Promise}
 */
export const updateInvestorProfile = (payload) => {
  return axios.put(getImbyRoute('/api/v2/public/authorizations'), { investor: payload })
}

/**
 * check for uniqueness of email
 * @param {Object} email
 * @return {Promise}
 */
export const checkUniqueness = (email) => {
  return axios.get(getImbyRoute('/api/v2/public/authorizations/validate_email'), { params: { email } })
}

/**
 * get investor data
 * @return {Promise}
 */
export const getInvestorData = () => {
  return axios.get(getImbyRoute('/api/investors/me'))
}

/**
 * verify email account
 * @param {String} email
 * @return {Promise}
 */
export const verifyEmailAccount = (email) => {
  return axios.post(getImbyRoute('/api/v2/public/authorizations/verify_email'), { email })
}

/**
 * resend verification email
 * @param {Object} payload email
 * @return {Promise}
 */
export const resendEmailVerification = (payload) => {
  return axios.post(getImbyRoute('/api/v2/authorizations/resend_email_verification'), payload)
}

/**
 * is addy handle available
 * @param {Object} addyHandle
 * @return {Promise}
 */
export const isAddyHandleAvailable = (addyHandle) => {
  return axios.get(getImbyRoute('/api/v2/public/authorizations/validate_addy_handle'), { params: { addy_handle: addyHandle } })
}

/**
 * get SIWA path
 * @return {Promise}
 */
export const getSocialButtonPaths = () => {
  return axios.get(getImbyRoute('/api/v2/public/authorizations/social_button_paths'))
}

/**
 * set investor country/province (social sign-on)
 * @param {Object} payload country, stateOrProvince
 * @return {Promise}
 */
export const setCountry = (payload) => {
  return axios.put(getImbyRoute('/api/investors/set_country'), payload)
}
