import BadWords from 'bad-words/lib/lang.json'
const dictionary = BadWords.words

export default (extend) => {
  extend('addy-handle', (value) => {
    if (value.length < 4) return 'minimum 4 characters'
    const noSpecialCharacters = /^\w{4,16}$/.test(value)
    if (!noSpecialCharacters) return 'no special characters except for underscore'
    const profanity = dictionary.some((word) => value.toLocaleLowerCase().includes(word))
    if (profanity) return 'No profanity 😖'
    return true
  })
}
