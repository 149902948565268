type SetSelectedDepartmentPayload = 'legal' | 'finance' | 'real estate'

interface State {
  selectedDepartment: SetSelectedDepartmentPayload
}

export default {
  namespaced: true,
  state: (): State => {
    return {
      selectedDepartment: 'legal',
    }
  },
  getters: {
    parsedDepartment(state: State) {
      return state.selectedDepartment.replace(/\s/g, '_')
    }
  },
  mutations: {
    setSelectedDepartment(state: State, payload: SetSelectedDepartmentPayload) {
      state.selectedDepartment = payload
    },
  },
}
