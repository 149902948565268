const localStorageServiceForAuth = () => {
  const _setToken = (tokenObj) => {
    localStorage.setItem('access_token', tokenObj.accessToken)
    if (tokenObj.refreshToken) {
      localStorage.setItem('refresh_token', tokenObj.refreshToken)
    }
  }
  const _getAccessToken = () => {
    return localStorage.getItem('access_token')
  }
  const _getRefreshToken = () => {
    return localStorage.getItem('refresh_token')
  }
  const _clearToken = () => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('investor')
    localStorage.removeItem('membershipDetails')
    localStorage.removeItem('disclaimer_shown')
  }
  return {
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
  }
}

export default localStorageServiceForAuth
