export default {
  namespaced: true,
  state: () => {
    return {
      mixpanel: null,
      identityIsSet: false,
    }
  },
  mutations: {
    setMixpanel(state, payload) {
      state.mixpanel = payload
    },
    setIdentity(state, payload) {
      state.identityIsSet = payload
    },
  },
}
