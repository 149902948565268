<template>
  <div v-if="bannerNotification != undefined" :class="`dropdown is-right ${showBannerNotification ? 'is-active' : ''}`">
    <div :class="`light-purple-button is-flex is-justify-content-center is-align-items-center mr-3 ${toggledOn ? 'is-new-notification' : ''}`" @click="toggleBannerNotification">
      <img src="@assets/icons/notification.svg" alt="notification icon">
    </div>
    <div class="dropdown-menu notification banner-notification mt-3" role="menu">
      <button class="delete" @click="toggleBannerNotification"></button>
      {{ bannerNotification.message }}
    </div>
  </div>
</template>

<script>
import { getBannerNotification, toggleOffBannerNotification } from '@api/common'

export default {
  data() {
    return {
      bannerNotification: undefined,
      showBannerNotification: false,
      toggledOn: false,
    }
  },
  created() {
    this.getBannerNotificationData()
  },
  methods: {
    toggleBannerNotification() {
      this.showBannerNotification = !this.showBannerNotification
      if (this.toggledOn && !this.showBannerNotification) {
        this.toggledOn = false
        toggleOffBannerNotification(this.bannerNotification.id).then((res) => {
          this.toggledOn = !res.success
        })
      }
    },
    getBannerNotificationData() {
      getBannerNotification().then((res) => {
        if (res.success) {
          this.bannerNotification = res.data.bannerNotification
          if (this.bannerNotification) {
            this.toggledOn = this.bannerNotification.toggledOn
            this.showBannerNotification = this.toggledOn
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.light-purple-button {
  width: 42PX;
  height: 42PX;
  border-radius: 50%;
}
.notification.banner-notification {
  background-color: #2D2926;
  color: #FFFFFF;
  border-radius: 12px;
  padding: 12px 30px 12px 12px;
  font-size: 16px;
  right: 12px;
  width: 327px;
  .delete {
    background-color: unset;
  }
}
.is-new-notification {
  display: relative;
  &:before {
    content: '';
    background: #C91717;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    left: 22px;
    top: 11px;
  }
}
</style>
