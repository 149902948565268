/* eslint quotes: [0, "single"] */
import { required } from 'vee-validate/dist/rules'

const transitNumber = (extend) => {
  extend('transitNumber', {
    ...required,
    validate: (value) => {
      return /^\d{5}$/.test(value)
    },
    message: `*must be 5 digits, prefix with zeros if less than 5.`
  })
}

const institutionNumber = (extend) => {
  extend('institutionNumber', {
    ...required,
    validate: (value) => {
      return /^\d{3}$/.test(value)
    },
    message: `*must be 3 digits, prefix with zeros if less than 3.`
  })
}

const accountNumber = (extend) => {
  extend('accountNumber', {
    ...required,
    validate: (value) => {
      return /^\d{6,}$/.test(value)
    },
    message: `*at least 6 digits, prefix with zeros if less than 6.`
  })
}

export {
  transitNumber,
  institutionNumber,
  accountNumber
}
