import { mobileCheck } from '@utils/common-methods/common'

export const handleDesktopRedirect = ({ to, from, next }) => {
  const desktopMapping = { 'dashboard': 'desktopDashboard' }

  if (!mobileCheck() && desktopMapping[to.name]) {
    next({ name: desktopMapping[to.name] })
    return true
  }
  return false
}
