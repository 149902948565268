const isSinValid = (value) => {
  // remove white spaces
  value = value.replace(/\s/g, '')
  if (value.length !== 9) {
    return false
  }

  // "167111111" x "121212121" -> [1,[1,2],7,2,1,2,1,2,1]
  // digit-by-digit-multiplication, note especially that 6x2 -> [1,2] in the above sample
  // "121212121" is prescribed by the SIN rules, here it's generated dynamically as (2-((i+1)%2))
  const toBeFlattened = value.split('').map((e, i) => (((2 - ((i + 1) % 2)) * Number(e)).toString().split('')))
  // [1,[1,2],7,2,1,2,1,2,1] -> [1,1,2,7,2,1,2,1,2,1]
  // flatten array, basically to treat carried digits as all the others
  const flattened = [].concat(...toBeFlattened)
  // sum them all up
  const digitSum = flattened.map(Number).reduce((a, b) => (a + b), 0)
  // and check if divisible by 10
  return (digitSum % 10) === 0
}

export default (extend) => {
  extend('valid-sin', {
    validate: isSinValid,
    message: `Error: invalid SIN`
  })
}
