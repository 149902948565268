import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required, email, confirmed } from 'vee-validate/dist/rules'
import passwordComplexity from './password-complexity'
import validSin from './valid-sin'
import { transitNumber, institutionNumber, accountNumber } from './bank-account-info'
import phoneNumber from './phone-number'
import addyHandle from './addy-handle'

export default (Vue) => {
  Vue.component('ValidationObserver', ValidationObserver)
  Vue.component('ValidationProvider', ValidationProvider)

  extend('required', {
    ...required,
    message: 'This field is required'
  })

  extend('email', {
    ...email,
    message: 'The email field must be a valid email'
  })

  extend('confirmed', {
    ...confirmed,
  })

  passwordComplexity(extend)
  transitNumber(extend)
  institutionNumber(extend)
  accountNumber(extend)
  phoneNumber(extend)
  addyHandle(extend)
  validSin(extend)
}
