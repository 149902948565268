import PasswordValidator from 'password-validator'

// Create a schema
const schema = new PasswordValidator()

// Add properties to it
schema
  .is().min(8) // Minimum length 8
  .is().max(100) // Maximum length 100
  .has().uppercase() // Must have uppercase letters
  .has().lowercase() // Must have lowercase letters
  .has().digits() // Must have digits
  .has().not().spaces() // Should not have spaces
  .has().symbols() // at least one symbol

export default (extend) => {
  extend('password-complexity', {
    validate: (value) => {
      return schema.validate(value)
    },
    message: 'The password must be at least 8 characters and contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character (e.g. , . _ & ? etc). Must have no spaces.'
  })
}
