<template>
  <div :class="`modal ${showModal ? 'is-active' : ''} ${modalClass}`">
    <div class="modal-background"></div>
    <div :class="modalContentClasses">
      <img src="@assets/icons/close.svg" alt="close" class="icon-close is-clickable" @click="closeModal" v-if="hasCloseIcon">
      <div :class="`modal-header has-text-weight-semibold has-text-centered ${titleClass}`">{{ title }}</div>
      <slot/>
      <div v-if="readMoreUrl != null" class="has-text-centered mt-5">
        <a class="has-text-blue has-text-weight-semibold read-more" @click="readBlog">{{ readMoreTitle }}</a>
      </div>
      <b-button v-if="buttonTitle != null" type="is-blue" class="has-text-weight-bold button-next mt-5" :loading="buttonIsLoading" @click="buttonWasTapped">{{ buttonTitle }}</b-button>
    </div>
  </div>
</template>

<script>
import { embeddedInIos } from '@utils/common-methods/common'

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function,
      required: true
    },
    title: {
      type: String
    },
    titleClass: {
      type: String
    },
    readMoreTitle: {
      type: String,
      default: 'Read more'
    },
    readMoreUrl: {
      type: String
    },
    buttonTitle: {
      type: String
    },
    buttonIsLoading: {
      type: Boolean,
      default: false
    },
    buttonTappedAction: {
      type: Function
    },
    customModalContentClasses: {
      type: Array,
      default: () => []
    },
    hasCloseIcon: {
      type: Boolean,
      default: true
    },
    modalClass: {
      type: String,
      default: ''
    },
  },
  computed: {
    modalContentClasses() {
      return 'modal-content has-background-white ' + this.customModalContentClasses.join(' ')
    },
  },
  methods: {
    readBlog() {
      if (embeddedInIos()) return window.webkit.messageHandlers.openURL.postMessage(this.readMoreUrl)
      window.open(this.readMoreUrl)
    },
    buttonWasTapped() {
      if (this.buttonTappedAction == null) {
        return this.closeModal()
      }
      this.buttonTappedAction()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

@keyframes clockwise-rotate {
  0% {
    transform: rotate(-3deg);
    font-size: 12px;
  }
  40% {
    transform: rotate(-3deg);
    font-size: 38px;
  }
  100% {
    transform: rotate(0deg);
    font-size: 28px;
  }
}

@keyframes zoom-in-and-out {
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(0);
  }
  90% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.modal {
  z-index: 101;
  padding: 0 24px;
  &.is-larger {
    padding: 0 8px;
    .modal-content {
      max-height: calc(100vh - 80px);
      padding-top: 24px;
      text-align: center;
      .button-next {
        font-size: 24px;
        width: fit-content;
      }
    }
  }
  .modal-content {
    max-width: $mobile-wrapper-width;
    position: relative;
    border-radius: 12px;
    padding: 2.8rem 1.5rem 1.5rem;

    // Custom modal content classes options
    &.has-background-confetti {
      &:before {
        content: "";
        background: url('../../assets/images/dashboard/confetti.png') center top/contain no-repeat;
        position: absolute;
        top: 2rem;
        left: 1.5rem;
        right: 1.5rem;
        bottom: 1.5rem;
      }

      .modal-header {
        margin-bottom: 50px;
      }
    }

    .icon-close {
      width: 28px;
      position: absolute;
      z-index: 10;
      left: 16px;
      top: 14px;
    }
    .modal-header {
      font-size: 28px;
      margin-bottom: 16px;
      position: relative;
    }
    .modal-text {
      position: relative;
      > div {
        font-size: 16px;
        line-height: 19px;
      }
    }
    .read-more {
      font-size: 14px;
    }
    .button-next {
      width: 100%;
      font-size: 16px;
      height: 43px;
      border-radius: 12px;
    }
  }

  &.owners-day {
    .modal-content {
      padding-top: 52px;
      min-height: 580px;
      .modal-header {
        width: 100%;
        animation: clockwise-rotate 1.5s ease-out;
        position: absolute;
        left: 0;
        top: 24px;
      }
      .button-next {
        animation: zoom-in-and-out 4s ease-out;
      }
    }
  }
}
</style>
