/* eslint-disable */
import { mobileCheck } from '@utils/common-methods/common'

if (mobileCheck()) {
  const baseSize = 16 // This is the size of 1rem
  function setRem() {
    const scale = document.documentElement.clientWidth / 375
    // Calculate the actual rem value and assign it to the html font-size
    document.documentElement.style.fontSize = (baseSize * scale) + 'px'
  }
  setRem()
  window.addEventListener('resize', () => {
    setRem()
  })
}
